import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getBreedBySlug } from '../../../api';
import Loader from '../../../components/loader';
import { baseURL } from '../../../constants';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {motion} from 'framer-motion'
const BreedDetails = () => {

    const [breed_data,setBreed]=useState({});
    const [loading,setLoading]=useState(false);
    const{slug}=useParams();

    useEffect(()=>{

        const fetchData=async()=>{
            setLoading(true)
            const res=await getBreedBySlug(slug);
  
            setTimeout(()=>{
                setBreed(res&&res[0]);
                setLoading(false)},1000)
           
        }

        fetchData()

    },[slug])

    const{breed,friendly_url,description,image,breed_standard,fci_group}=breed_data;
    const imgUrl=baseURL+"/storage/app/public/breed_images/"

    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }

    const pageTransition={
        in:{
            opacity:1,
            x:0
        },
        out:{
            opacity:0,
            x:'-100vw'
        }
    }
    return (
        <motion.div
        variants={pageTransition}
        initial="out"
        animate="in"
        exit="out"
        className="container mt-5">
            <div className="row shadow-sm rounded  py-2">
                     {loading && (
                        <Skeleton
                            width={'50%'}
                            height={40}
                           
                        />
                      )||  <h1>{breed}</h1>}
                   
                <div className="col-md-8 order-md-1 order-2  flex-column align-items-end justify-content-start">
                {loading && (
                        <Skeleton
                            width={'100%'}
                            height={18}
                            count={9}
                           
                        />
                      )||   <div dangerouslySetInnerHTML={{__html: convertHTMLEntity(description)}}></div>}
               
                {/* <p align="justify" style={{lineHeight:1.6,fontSize:'18px'}}>{description}</p> */}
                <div className="row justify-content-between">
                    <div className='col-lg-4 col-md-4 justify-content-start align-items-center my-3'>
                    {loading && (
                        <Skeleton
                            width={'50%'}
                            height={40}
                           
                        />
                      )||
                      
                        <Link to="#" className="butn shadow-sm butn-grey">Breed Standard</Link>}
                    </div>
                    <div className='col-lg-4 col-md-4 justify-content-end align-items-center my-3'>

                    {loading && (
                                          <Skeleton
                                                width={'50%'}
                                                height={40}
                                            
                                            />
                                        )||   <Link to={`/gdcp-database/${friendly_url}`} 
                                        className="butn shadow-sm butn-grey">Breed Database</Link>
                                            }
                    </div>
                      
                    </div>
                </div>
                <div className="col-md-4 order-md-2 order-1">
                    <div className="row justify-content-center align-items-center flex-column">
                    {loading && (
                        <Skeleton
                            style={{marginLeft:50}}
                            width={'85%'}
                            height={250}
                           
                        />
                      )|| 
                            <LazyLoadImage
                        alt={breed}
                        effect="blur"
                        maxHeight={250}
                        aiamgelt="Card image cap"
                        className="card-img-top p-2" 
                        width={'auto'}
                        src={imgUrl+image} />}
                      
  {/* <img src={imgUrl+image} alt={breed} className="h-100 w-75 shadow-sm border rounded"/> */}
                   
                   {loading && (
                        <Skeleton
                            style={{marginLeft:50}}
                            width={'85%'}
                            height={40}
                           
                        />
                      )||
                     <h6 className="fw-bold p-1 py-3 w-75">{fci_group}</h6>}
                    </div>
                 
                </div>
             
               
               
            </div>
     
        </motion.div>
    )
}

export default BreedDetails
