import React, { useContext, useEffect, useState } from "react";
import { getMemberAccounts, getMemberAccountsByDate } from "../../api";
import { toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import { GlobalContext } from "../../context/store";
import FundTransferModal from "./FundTransferModal";

const BalanceStatment = () => {
  const [openModal, setOpenModal] = useState(false);
  const [toDate, setTodate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [balArr, setBalArr] = useState([]);

  const { user } = useContext(GlobalContext);

  const Statement = async () => {
    const memberId = user?.account_id;
    const res = await getMemberAccounts(memberId);
    setTransactions(res);

    let balArr = [];
    let b = 0;
    for (let t = 0; t < res.length; t++) {
      let debit = res[t]?.debit;
      let credit = res[t]?.credit;

      if (debit) {
        b = b - parseInt(debit);
        balArr.push(b);
      }

      if (credit) {
        b = b + parseInt(credit);
        balArr.push(b);
      }
    }
    setBalArr([...balArr]);

    console.log(res);
  };

  useEffect(() => {
    const fetchData = async () => {
      const memberId = user?.account_id;
      const res = await getMemberAccounts(memberId);
      let debit =
        res && res?.map((item) => item.debit).reduce((n, acc) => acc + n, 0);
      let credit =
        res && res?.map((item) => item.credit).reduce((n, acc) => acc + n, 0);
      setBalance(credit - debit);
    };
    fetchData();

    Statement();
  }, []);

  const notify = () =>
    toast.info("Please Select Date Range", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const filterAccounts = async () => {
    if (!toDate || !fromDate) {
      notify();
    } else {
      setTransactions([]);
      const memberId = JSON.parse(localStorage.getItem("user"))?.account_id;
      const res = await getMemberAccountsByDate({
        toDate: new Date(toDate)?.toISOString().slice(0, 10),
        fromDate: new Date(fromDate).toISOString().slice(0, 10),
        memberId: memberId,
      });
      setTransactions(res);

      let balArr = [];
      let b = 0;
      for (let t = 0; t < res.length; t++) {
        let debit = res[t]?.debit;
        let credit = res[t]?.credit;

        if (debit) {
          b = b - parseInt(debit);
          balArr.push(b);
        }

        if (credit) {
          b = b + parseInt(credit);
          balArr.push(b);
        }
      }
      setBalArr([...balArr]);
    }
  };

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  return (
    <div className="">
      <FundTransferModal open={openModal} setOpen={setOpenModal} />
      <div className="bg-light d-flex justify-content-between p-2 rounded shadow-sm">
        <div>
          <h3>Account Transactions </h3>
          <span className="badge bg-primary">Current Balance: {balance}</span>
        </div>
        {/* <div>
          <button
            onClick={() => setOpenModal(true)}
            className="shadow-sm btn btn-secondary mx-2"
          >
            Fund Transfer
          </button>
        </div> */}
      </div>
      <div className="bg-white my-4 mx-0 row p-2 rounded shadow-sm">
        <div className="col-md-3">
          <input
            type="date"
            className="form-control"
            value={toDate}
            onChange={(e) => setTodate(e.target.value)}
          />
        </div>

        <div className="col-md-3">
          <input
            type="date"
            className="form-control"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </div>

        <div className="col-md-3">
          <input
            type="button"
            className="btn btn-warning my-0 shadow-sm text-white"
            value="Show Statment"
            onClick={() => filterAccounts()}
          />
        </div>
      </div>

      <div className="mt-3 bg-light shadow-sm p-2 rounded">
        <table className="responsive-table table border bg-white">
          <tr>
            <th>Date</th>
            <th>Remarks</th>
            <th>Paid</th>
            <th>Deposit</th>
            <th>Balance</th>
          </tr>
          {transactions
            ? transactions?.map((action) => (
                <tr className="p-3">
                  <td style={{ maxWidth: "30%" }}>
                    {new Date(action.created_at).toDateString()}
                  </td>
                  <td>{action.remarks}</td>
                  <td>{action.debit || "-"}</td>
                  <td>{action.credit || "-"}</td>
                  {/* <td>{arr[key-1]?.credit-arr[key]?.debit || credit || debit}</td> */}
                  <td>{balArr[transactions.indexOf(action)]}</td>
                </tr>
              ))
            : null}
        </table>
      </div>
    </div>
  );
};

export default BalanceStatment;
