import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllJudges, getTeam } from "../../../api";
import placeHolder from "../../../assets/Images/userPlaceholder.png";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import {motion} from 'framer-motion'
const Judges = () => {
  const [judges, setJudges] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllJudges();
      setJudges(res && res);
    };
    fetchData();
  }, []);

  const imgURL="https://admin.gundogclub.pk/public/judge_images/";
  const pageTransition={
    in:{
        opacity:1,
        x:0
    },
    out:{
        opacity:0,
        x:'-100vw'
    }
}
return (
    <motion.div
    variants={pageTransition}
    animate="in"
    initial="out"
    exit="out"
    className="container"
    >
      <h1>GDCP Judges</h1>
      <div className="row">
        {judges && judges.length
          ? judges?.map(({ full_name,facebook_url,youtube_url,twitter_url,description,position_in_club, image, url_link }) => (
              <div class="col-md-4 container mt-4 mb-4 p-3 d-flex justify-content-center">
                <div class="p_card p-4 shadow bg-white">
                  <div class=" image d-flex flex-column justify-content-center align-items-center">
                  <div>
                  {image ? (
                        <LazyLoadImage
                          alt={full_name}
                          effect="blur"
                          Height={'200px'}
                          width={'200px'}
                          aiamgelt="Card image cap "
                          className="card-img-top p-2 rounded-circle shadow mb-2"
                          src={imgURL +image}
                        />
                      ) : (
                        <img
                          className="card-img-top rounded-circle"
                          src={placeHolder}
                          alt={full_name}
                          aiamgelt="Card image cap"
                          style={{ maxHeight: 200, width: "auto" }}
                        />
                      )}
                  </div>
                 
                  
                    {/* <img
                      className="shadow rounded-circle"

                      src={image?imgURL+image:placeHolder}
                      alt={full_name} 
                      height="150"
                      width="158"
                    /> */}
                    <span class="name text-center mt-3">{full_name}</span>{" "}
                    <span class="idd">{position_in_club}</span>
                    <div class=" d-flex mt-2">
                      {" "}
                      <Link
                        to={url_link || "#"}
                        className="btn  butn-grey shadow-sm"
                      >
                        Read Bio
                      </Link>{" "}
                    </div>
                    <div class="gap-3 mt-3 icons d-flex flex-row justify-content-center align-items-center">
                        {
                            facebook_url?
                            <a href={facebook_url||"#"}><i class="fab fa-facebook"></i></a>:null
                        }

                        {
                            youtube_url?
                            <a href={youtube_url||"#"}><i  class="fab fa-youtube"></i></a>
                            :null

                        }
                        {
                        twitter_url?
                         <a href={twitter_url||"#"}><i class="fab fa-twitter"></i></a>
                         :null
                        }
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </motion.div>
  );
};

export default Judges;
