import React, { useEffect, useState } from 'react'
import { getAllBreeds } from '../api'
import Breed from './breed'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const BreedSlider = () => {
    
    const [breeds,setBreeds]=useState([])

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getAllBreeds()
            setBreeds(res&&res)
        }
        fetchData()
    },[])

    return (
        <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={1000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
         >
        {
            breeds&&breeds?.map(({breed,friendly_url,image},_i)=>(
                    <div key={_i}>
                    <Breed title={breed} image={image} link={friendly_url}/>
                  </div>
                    ))
                }
      </Carousel>
          
   
    )
}

export default BreedSlider;

