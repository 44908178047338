import React from 'react'
import { Outlet } from 'react-router'
import Sidebar_About from '../../layouts/SideNavigation_About'
import {motion} from 'framer-motion';

const About = () => {
    const pageTransition={
        in:{
            opacity:1,
            x:0
        },
        out:{
            opacity:0,
            x:'-100vw'
        }
    }

    return (
        <motion.div 
        initial="out"
        animate="in"
        exit="out"
        variants={pageTransition}
        className="p-5 mt-5 container">
            <div className="row">
                <div className="col-md-3">
                    
            <Sidebar_About/>
                </div>
                <div className="col-md-9">
                    <Outlet/>
                </div>
            </div>
        </motion.div >
    )
}

export default About    
