import React, { useEffect, useState } from 'react';
import { MDBDataTableV5,MDBBadge } from 'mdbreact';
import { Link } from 'react-router-dom';
import { getAllStudCertificates } from '../../api';

export default function StudCertificates() {

  const [data,setData]=useState([])
  
  useEffect(() => {
    const fetchData=async()=>{
      const res=await getAllStudCertificates()
      setData(res?.map(({sire,dam,mating_date,created_at,created_by,status})=>({sire,dam,mating_date,created_at,created_by,status})))
    }
    fetchData()
  }, [])

  var datatable={
      columns: [
      {
        label: 'Sire',
        field: 'sire',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Dam',
        field: 'dam',
        width: 270,
      },
      {
        label: 'Created By',
        field: 'created_by',
        sort: 'disabled',
        width: 150,
      },
     
      
     
    ],
    rows:data
  }

  const badgesData = {
    columns: [
      ...datatable.columns,
      {
        label: 'Mating Date	',
        field: 'mating_date',
        width: 200,
      },
      {
        label: 'Created Date',
        field: 'created_at',
        sort: 'asc',
        width: 100,
      },
     
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Actions',
        field: 'actions',
      },
    ],
    rows: [
      ...datatable.rows.map((row, order) => ({
        ...row,
        status: (
          <MDBBadge style={{color:'black',backgroundColor:'lightgrey'}} pill color='primary' className='p-1 px-2' key={order} searchvalue={order}>
            {row?.status}
          </MDBBadge>
        ),
        actions: (
          <MDBBadge  pill color='primary' className='p-1 px-2' key={order} searchvalue={order}>
            <Link to="#"><i style={{fontSize:18}} className="fas fa-eye"></i></Link>
          </MDBBadge>
        ),
        mating_date:(<span>{row?.mating_date?.slice(0,10)}</span>),
        created_at:(<span>{row?.created_at?.slice(0,10)}</span>)
      })),
    ],
  };

  return( 
      <>
      <div className="d-flex justify-content-between align-items-center">

      <h1>Stud Certificate - List</h1>
      
      <Link to={`create`}><button className="btn btn-primary shadow"><i className="fas fa-plus-circle"></i>&nbsp;New</button></Link>
      </div>
  <MDBDataTableV5 className="shadow mt-2 p-2 rounded " 
  hover entriesOptions={[10, 20, 25.,50]} entries={10}
   pagesAmount={10} data={badgesData} sortRows={['badge']} searchTop searchBottom={false} materialSearch />
   </>
  );
}
