import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import Select from "react-select";
import { toast } from 'react-toastify';
import { getAccountHead, getAllClasses, getAllDogsByOwner, getCatalogId, getDogById, getEventBySlug, getMemberAccounts, showDogSave } from '../../../api'
import { GlobalContext } from '../../../context/store';
import { useNavigate } from "react-router-dom";

const ShowEntryForm = () => {

    const [dogs,setDogs]=useState([])
    const [show_dogs,setShow_dogs]=useState([])
    const [dogID,setDogId]=useState(null)
    const [event,setEvent]=useState({})
    const [classes,setClasses]=useState([])
    const [balance,setBalance]=useState(0)
    const dogRef = useRef(null)
    const {slug}=useParams()
    let navigate = useNavigate();

    const {user}=useContext(GlobalContext)

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getAllDogsByOwner(user?.id)
            setDogs(res)
            const memberId=user?.account_id
            const transactions=await getMemberAccounts(memberId);
            let debit=transactions&&transactions?.map((item)=>(item.debit)).reduce((n,acc)=>(acc+n),0)
            let credit=transactions&&transactions?.map((item)=>(item.credit)).reduce((n,acc)=>(acc+n),0)
            setBalance(credit-debit)
            if(credit-debit <= 0)
            {
                navigate(`/member/member-account-statement`);
            }
        }
        fetchData()
        },[])

        useEffect(async() => {
            const data_e=await getEventBySlug(slug)
            setEvent(data_e&&data_e[0])           
        }, [slug])
        
        useEffect(async() => {
            const cls_res=await getAllClasses({eventType:"'"+event?.type+"'"})
            setClasses(cls_res&&cls_res)
        }, [event])


        function monthDiff(d1, d2) {
            var months;
            months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
            months -= new Date(d1).getMonth();
            months += new Date(d2).getMonth();
            const res=classes?.filter(({to_age,from_age})=>(months>=from_age && months<=to_age))
            return  res
        }

        const DeleteDog=(did)=>{
            const r=show_dogs?.filter(({id})=>id==did)
            const del=show_dogs?.filter(({id})=>id!=did)
            setShow_dogs(del)
            setDogs([...dogs,{...r[0],dog_id:r[0]?.id}])
            notify("Dog delete from the list !")
        }

        const AddDog=async(id)=>{ 
            if(id){
                const res=await getDogById(id)
                let cat_id=await getCatalogId({showId:event?.id,groupId:res&&res[0]?.group_id})
                let cid=cat_id&&cat_id[0]?.catalog_id
                setShow_dogs([...show_dogs,{...res&&res[0],catalog_id:cid}])
                setDogs(dogs?.filter((dog)=>(dog?.dog_id!=id)))
                dogRef.current.clearValue()
            }
            else{
                notify("Please select dog!")
                }
        }

        const AddDogClass=async(id,dogClass)=>{
            if(id&&dogClass){
                const res=show_dogs?.filter(dog=>(dog.id==id))[0]
                if('classname' in res){
                    for(let i in show_dogs){
                        if(show_dogs[i]?.id==id){
                            show_dogs[i].classname=dogClass
                        }
                    }
                }
                else{
                    const ad={...res,classname:dogClass}
                    for(let i in show_dogs){
                        if(show_dogs[i]?.id==id){
                            show_dogs[i]=ad
                        }
                    }
                }
            }
            else{
                    notify("Please select dog!")
                }
        }

        const saveDog = async () => {
          if (validate()) {
            let dt = show_dogs?.map(
              ({ id, breed, group_id, classname, owners, catalog_id }) => ({
                dog_id: id,
                breed_id: breed,
                group_id: group_id,
                show_id: event?.id,
                class: classname,
                current_owner: owners,
                catalog_id: catalog_id
                  ? parseInt(catalog_id) + 1
                  : group_id + "01",
              })
            );

            const dd=await getAccountHead(user?.id)
            const res = await showDogSave(
                {
                    data:dt,
                    debit:{
                        account_id:user?.account_id,
                        account_head:dd[0]?.company_head,
                        debit:event?.fee,
                        remarks:"Dog show entry for "+event?.title,
                        ref_id:event?.id,
                        ref_model:"Dog Show",
                        created_at:new Date().toISOString().slice(0, 10),
                        updated_at:new Date().toISOString().slice(0, 10)
                        }
                    }
                );
            if (res) {
              setShow_dogs([]);
              notify("Dogs save successfully !");
            }
          }
        };

        const notify = (msg) => toast.info(msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

        const validate = () => {
          let dogs = show_dogs;
          var isValid = false;
          for (let j of dogs) {
            if (!j?.classname) {
              var d = show_dogs?.filter(({ id }) => id != j?.id);
              var c = show_dogs?.filter(({ id }) => id == j?.id);
              setShow_dogs([...d, { ...c[0], invalid: true }]);
              isValid=false;
            } else {
              console.log("no empty class");
               isValid=true;
            }
            }      
                return isValid;
        };

    return (
        <div>
            <h1>Show Entry Form</h1>
            <div className="form container">
                <div className="row">
                    <div className="col-md-4 my-2">
                        <input className="form-control" type="text" disabled value={user?.first_name+" "+user?.last_name}/>
                    </div>
                    <div className="col-md-6 my-2">
                    <Select
                            ref={dogRef}
                           options={dogs&&dogs?.map(item=>({label:item.dog_name,value:item?.dog_id}))}
                           onChange={
                            (e)=>{setDogId(e?.value)
                            console.log(e)}
                        }
                        />
                    </div>
                    <div className="col-md-2 my-2" >
                        <button className="butn butn-grey shadow-sm rounded" onClick={()=>{AddDog(dogID)}}>Add</button>
                    </div>
                    {
                        show_dogs&&show_dogs?.map(({invalid,sire_KP,dam_KP,id,dog_name,sex,dob,breeder,KP,dam_name,
                            sire_name,registration,microchip,elbows,hip,dam_reg_no,sire_reg_no})=>(
                                <div className="col-md-10 py-2 mx-2 my-3 shadow-sm rounded border bg-light show-entry-main">
                            <span onClick={()=>{DeleteDog(id)}} style={{left:'100%',opacity:.8}} 
                            className="close-btn shadow px-2 py-1 bg-danger text-light fw-bold position-relative">X</span>
                            <div className="row  ">
                                {invalid?
                                <div class="mx-2 alert alert-warning col-10" role="alert">
                                   <i class="fas fa-exclamation-triangle mx-2"></i> Please select class!
                                 </div>
                                :null
                                }
                                <h2>{dog_name}<i class={`mx-2 fas ${sex=="Male"?"fa-mars":"fa-venus"}`}></i></h2>
                                <div className="col-md-4 my-3 ">
                                    <div>
                                    <label className="fw-bold">Date Of Birth:</label>
                                    <span className="mx-2">{new Date(dob)?.toDateString()||"-"}</span>
                                    </div>
                                    <div>
                                    <label className="fw-bold">Sire:</label>
                                    <span className="mx-2">{sire_name}</span>
                                    </div>
                                    <div>
                                    <label className="fw-bold">Dam:</label>
                                    <span className="mx-2">{dam_name}</span>
                                    </div>
                                    <div>
                                    <label className="fw-bold">Breeder:</label>
                                    <span className="mx-2">{breeder||"-"}</span>
                                    </div>
                                    <div className="d-flex my-2">
                                    <label className="fw-bold ">Class:</label>
                                    <Select
                                    className="mx-2 "
                                    options={
                                        monthDiff(dob,event?.start_date)?.map
                                        (item=>({label:item.class,value:item.id}))   
                                        }
                                    onChange={(e)=>{AddDogClass(id,e.value)}}
                                    
                                    />  
                                    </div>
                                </div>
                                <div className="col-md-4 my-3 ">
                                <div>
                                    <label className="fw-bold">Reg No:</label>
                                    <span className="mx-2">{registration||KP?"KP: "+KP:"-"}</span>
                                    </div>
                                    <div>
                                    <label className="fw-bold">Sire Reg No:</label>
                                    <span className="mx-2">{sire_reg_no||sire_KP?"KP: "+ sire_KP:"-"}</span>
                                    </div>
                                    <div>
                                    <label className="fw-bold">Dam Reg No:</label>
                                    <span className="mx-2">{dam_reg_no || dam_KP?"KP: "+dam_KP:"-"}</span>
                                    </div>
                                    <div>
                                    <label className="fw-bold">HD:</label>
                                    <span className="mx-2">{hip||"-"}</span>
                                    </div>
                                </div>
                                <div className="col-md-4 my-3 ">
                                <div>
                                    <label className="fw-bold">Tattoo/Microchip:</label>
                                    <span className="mx-2">{microchip||"-"}</span>
                                    </div>
                                    <div>
                                    <label className="fw-bold">ED: </label>
                                    <span className="mx-2">{elbows||"-"}</span>
                                    </div>
                                
                                </div>
                            </div>
                    </div>
                        ))
                    }
                 <div className="d-flex justify-content-end col-md-10">
                     {
                         show_dogs?.length?
                         <input  type="submit"  onClick={()=>saveDog()} className="shadow-sm btn btn-primary shadow"/>:
                         null
                     }
                 </div>
                </div>
            </div>
            <div className="user-balance mx-3 border rounded shadow-sm col-md-10 shadow p-2 mt-5">
                <h5 className="fw-bold text-dark">Owner(s) Details - Current Balance: <span className="balance-amount"><span className="fw-normal text-success">Rs. </span>{balance.toLocaleString()}</span> </h5>
                <div className="row my-2">
                    <div className="col-md-6"><span className="fw-bold mb-2 ">Name:</span>
                    <span className="mx-2">{user?.first_name+" "+user?.last_name}</span></div>
                    <div className="col-md-6"><span className="fw-bold  mb-2" >Email:</span>
                    <span className="mx-2">{user?.email}</span>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-md-6"><span className="fw-bold  mb-2">Phone:</span>
                    <span className="mx-2">{user?.phone}</span>
                    </div>
                    <div className="col-md-6"><span className="fw-bold  mb-2">Address: </span>
                    <span className="mx-2">{user?.address}</span>
                    </div>
                </div>
            </div>       
        </div>
    )
}
export default ShowEntryForm
