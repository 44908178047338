import React, { useState, useEffect, useRef } from "react";
import { dogSearch, getAllBreeds, getAlldogs, getAllDogsByBreed, getBreedIdBySlug } from "../../api";
import placeHolder from "../../assets/Images/placeholderdog.jpg";
import { getUnique } from "../../utils";
import loader from "../../assets/Images/loader.gif";
import { Link,useParams,useNavigate } from "react-router-dom";
import Select from "react-select";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {motion} from 'framer-motion';

const Dogs = () => {
  const [dogsData, setDogsData] = useState([]);
  const [searchLetters, setLetters] = useState([]);
  const [dogs, setDogs] = useState([]);
  const [breeds, setBreeds] = useState([]);
  const [breed, setBreed] = useState({});
  const [loading, setLoading] = useState(false);

  const{slug}=useParams()
  const breedRef = useRef(null)
  var navigate=useNavigate()
  useEffect(()=>{
    setLoading(true)
    const fetchData=async()=>{
      const res__=await getBreedIdBySlug(slug)
      setBreed(res__&&res__[0])
      const data = await getAllDogsByBreed(res__&&res__[0]?.id);
      setDogs(data);
      const res_=await getAllBreeds()
      setBreeds(res_&&res_)
      setTimeout(()=>{   setLoading(false)},1000)
    }
    fetchData()

  },[slug,])

  useEffect(async () => {
    let letter_arr = [];
    if (dogsData) {
      for (let i of dogs) {
        letter_arr.push(i?.dog_name?.charAt(0));
      }
    }
    setLetters(getUnique(letter_arr));
  }, [dogsData]);

  useEffect(async () => {
    setDogsData(dogs);
    let letter_arr = [];
    if (dogsData) {
      for (let i of dogs) {
        letter_arr.push(i?.dog_name?.charAt(0));
      }
    }
    setLetters(getUnique(letter_arr));
  }, [dogs]);

  const filterByFirstLtr = (letter) => {
    if (letter == "*") {
      setDogsData(dogs);
    }
    else {
      const data = dogs?.filter((data) => data.dog_name.charAt(0) == letter);
      setDogsData(data);
    }
  };

  const handleChange = async (e) => {
    setLoading(true);
    let result = await dogSearch(e);
    if (e === "") {
      setDogsData(dogs);
      let letter_arr = [];
      if (dogsData) {
        for (let i of dogs) {
          letter_arr.push(i?.dog_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
    } else {
      setDogsData(result);
      let letter_arr = [];
      if (dogsData) {
        for (let i of dogs) {
          letter_arr.push(i?.dog_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
    }
    setLoading(false);
  };

const pageTransition={
  in:{
      opacity:1,
      x:0
  },
  out:{
      opacity:0,
      x:'-100vw'
  }
}

const imageURL="https://admin.gundogclub.pk/public/dog_images/"
return (
<motion.div 
initial="out"
animate="in"
exit="out"
variants={pageTransition}
className="mt-5 container">
      <div className="bg-white rounded p-2 mt-5 container ">
      <h1>{slug}</h1>

      {/* <div className="col-md-2  rounded">
        <div className="logo">
          <img className="" src={logo} />
        </div>
        <hr />
      </div> */}
      <div className="col-md-12 my-2 px-0 ">
        <div className="row my-3">
        <div className="container col-md-4">
        <Select
                placeholder={ breed?.breed}
                ref={breedRef}
                options={breeds?.map(({friendly_url,breed})=>({value:friendly_url,label:breed}))}
                onChange={(e) => navigate(`/gdcp-database/${e?.value}`)}
              />
      </div>
      <div className="container col-md-8 d-flex justify-content-end px-2">
        <input
          type="search"
          className="form-control search-input  mx-3"
          PlaceHolder="Search by Dog Name, KP, Microchip No. "
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
        </div>
   
      <div className="container  w-100 flex-wrap d-flex justify-content-end px-2 ">
        <button
          onClick={() => {
            filterByFirstLtr("*");
          }}
          className="filterBtn my-2   btn shadow-sm fw-bold border"
        >
          All
        </button>
        {searchLetters &&
          searchLetters.filter(item=>/[a-zA-Z]/.test(item)).sort().map((item, ind) => (
            <button
              key={ind}
              onClick={() => {
                filterByFirstLtr(item);
              }}
              className="filterBtn  my-2  btn shadow-sm fw-bold border"
            >
              {item}
            </button>
          ))}
            {/* <button
          onClick={() => {
            filterByFirstLtr("asc");
          }}
          className="filterBtn my-2 "
        >
          A - Z
        </button>
            <button
          onClick={() => {
            filterByFirstLtr("dec");
          }}
          className="filterBtn my-2 "
        >
          Z - A
        </button> */}
      </div>
   

      <div className="row row-flex">
        {loading ? (
          [0,1,2,3,4,5,6,7,8,9,10,11].map((item)=>(
            <div   className="my-2 col-md-3  col-sm-6">
          <Skeleton
            height={220}
         />
          <Skeleton
            height={20}
         />
         <hr/>
            </div>
          
          ))
       
          
        ) : dogsData && dogsData.length ? (
          dogsData?.map(({id,friendly_URL, dog_name, image }) => (
            <div className="col-md-3 col-sm-6 row-flex-content">
            <Link to={`/dog/bio/${friendly_URL}`} style={{textDecoration:"none"}}>
            <div className="card d-flex flex-column justify-content-between aling-items-center shadow-sm my-2 mx-2" style={{minHeight:'305px'}}>
              <div className="breed-img w-100">
                {!image? (
                  <img
                  className="card-img-top"
                  src={placeHolder}
                  alt={dog_name}
                />
                ) : (
                  <img
                  className="card-img-top"
                  src={
                    imageURL +id+"/"+image
                  }
                  alt={dog_name}
                />
                )}
              </div>
              <div className="card-body">
                <h5 className="card-title ">{dog_name}</h5>
                {/* <a href="#" className="btn-grad">Go somewhere</a> */}
              </div>
            </div>
            </Link>
            </div>
          ))
        ) : <h2>No dogs found !</h2>}
      </div>
      </div>
      <div className="col-md-2  rounded justify-content-center align-items-center">
        {/* <img className="rounded mt-2 w-100" src={sideimg} /> */}
      </div>
    </div>

    </motion.div>
  );
};

export default Dogs;
