import React from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import { Link } from 'react-router-dom';

const LitterRegistration=()=>{
  const [datatable, setDatatable] = React.useState({
    columns: [
     
          {
            label: 'Dob',
            field: 'Dob',
            width: 120,
          },
      {
        label: 'Sire',
        field: 'sire',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Dam',
        field: 'Dam',
        width: 270,
      },
      
      {
        label: 'Created By',
        field: 'Created By',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Status',
        field: 'Status',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Actions',
        field: 'Actions',
        sort: 'disabled',
        width: 100,
        
      },
    ],
    rows:[]
  });

  return( 
      <>
      <div className="d-flex justify-content-between align-items-center">

      <h1>Litters Registration Requests - List</h1>
      <Link to="create" className="btn btn-primary shadow"><i className="fas fa-plus-circle"></i>&nbsp;Create New Request</Link>
      </div>
  <MDBDataTableV5 className="shadow mt-2 p-2 rounded " 
  hover entriesOptions={[10, 20, 25.,50]} entries={10}
   pagesAmount={10} data={datatable} searchTop searchBottom={false} materialSearch />
   </>
  );
}

export default LitterRegistration;