import React, { useContext, useState } from "react";
import { signIn, userlogin } from "../../../api";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../context/store";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Login = (e) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { setAuthUser } = useContext(GlobalContext);

  const loginHandler = async (e) => {
    // e.preventdefault()

    const token = await executeRecaptcha();
    setLoading(true);
    let formData = new FormData();
    formData.append("username", user);
    formData.append("password", password);
    formData.append("g_recaptcha", token);

    const res = await userlogin(formData);

    if (res.status == 200) {
      if (user && password) {
        const auth = await signIn({
          username: user,
          password,
        });
        if (auth.success == 0) {
          alert(auth.message);
        } else if (auth.success == 1) {
          setAuthUser(auth?.data);
          localStorage.setItem("token", auth?.token);
          localStorage.setItem("user", JSON.stringify(auth?.data));
          navigate(`/member/my-profile`);
        }
      }
    } else {
      toast.error("Credentials did not match any user. Please try again", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false);
  };

  return (
    <div className=" d-flex justify-content-center align-items-center login-wraper">
      <div className="login-main  shadow  d-flex justify-content-center align-items-center">
        <div className="login-frm d-flex justify-content-center flex-column align-items-center w-100">
          <h4 className=" mb-4">Login to GDCP</h4>
          <form className="d-flex justify-content-center flex-column align-items-center w-100">
            <div className="form-group my-2 w-75">
              <input
                onChange={({ target }) => setUser(target.value)}
                type="text"
                className="form-control"
                placeholder="Username"
                required
              />
            </div>
            <div className="form-group my-2 w-75">
              <input
                type="password"
                onChange={({ target }) => setPassword(target.value)}
                className="form-control"
                placeholder="Password"
                required
              />
            </div>
            {/* <div className="d-flex justify-content-between align-items-center">
                      <a>Forgot Password?</a>
                      <a></a>
                    </div> */}
            {loading ? (
              <CircularProgress
                sx={{
                  mt: 1,
                }}
                color="success"
              />
            ) : (
              <input
                onClick={(e) => loginHandler()}
                type="button"
                value="Login"
                className="mt-4 w-25 btn btn-light shadow"
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
