import React, { useState, useEffect, useContext } from "react";
// import logo from '../../assets/Images/website_logo_large-300x241.png';
import placeHolder from "../../assets/Images/image_paceholder.png";
import { GlobalContext } from "../../context/store";
import { Outlet, useParams, useNavigate } from "react-router";
import {
  getAllDogsByOwner,
  getKennelsByOwner,
  getUserById,
  topUpAccount,
  getMemberAccounts,
} from "../../api";
import ProgenyChild from "../../components/progeny_child";
// import Kennels from "../../Kennels";
import Kennel from "../../components/kennel";
import Sidebar_Member from "../../layouts/SideNavigation_Member";
import EditProfile from "../../Modals/EditProfile";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { POST_BACK_URL_1, STORE_ID } from "./config";
import { Redirect } from "react-router-dom";
import { convertHashKey, currentTimeStamp } from "../../utils";
import { toast } from "react-toastify";
import TopUpModal from "../../Modals/topup_modal";

const MYProfile = () => {
  const { user, setAuthUser } = useContext(GlobalContext);
  const [user_data, setUserData] = useState();
  const [topup_amount, set_topup_amount] = useState(0);
  const [kennels, setKennels] = useState([]);
  const [dogs, setDogs] = useState([]);
  const [breedings, setBreedings] = useState([]);
  const [url_params, setParams] = useState();

  const [wallet, setwallet]=useState(0);

  const [delinquent, setdelinquent]=useState(false);

  let navigate = useNavigate();

  const balance=async()=>{
    const memberId=user?.account_id
    const res=await getMemberAccounts(memberId);
    let debit=res&&res?.map((item)=>(item.debit)).reduce((n,acc)=>(acc+n),0)
    let credit=res&&res?.map((item)=>(item.credit)).reduce((n,acc)=>(acc+n),0)
    setwallet(credit-debit)

    if(credit-debit <= 0)
    {
      setdelinquent(true);
    }
}

  useEffect(async () => {
    const res = await getUserById(user?.id);
    console.log(res, "user");
    setUserData(res && res[0]);
    const kenl_res = await getKennelsByOwner(user?.id);
    setKennels(kenl_res);
    const dogs_res = await getAllDogsByOwner(user?.id);
    setDogs(dogs_res && dogs_res);

    postTopUpHandle();
    balance();
  }, []);

  const postTopUpHandle = async () => {
    const url = new URL(window.location);
    const amount = url.searchParams.get("amount");
    const TRN = url.searchParams.get("transactionRefNumber");
    if (TRN && amount) {
      var formdata = new FormData();
      formdata.append("amount", amount);
      formdata.append("transactionId", TRN);
      formdata.append("user_id", user?.id);
      // const data={amount:amount,transactionId:TRN,user_id:user?.id}
      const res = await topUpAccount(formdata);
      if (res.status === 200) {
        notify(`Your account has been credited by ${amount}`, "warning");
        // window.location.href = "http://localhost:3000/member/my-profile";
        window.location.href = "https://gundogclub.pk/member/my-profile";
      }
    }
  };

  const notify = (msg, type) =>
    toast[type](msg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const topUpHandle = async () => {
    if (topup_amount <= 0 || !topup_amount) {
      notify("Please Enter Topup Amount", "warning");
    } else {
      const url = "https://easypaystg.easypaisa.com.pk/tpg/?";
      const tempURLparams = `amount=${topup_amount}&orderRefNum=1222&paymentMethod=InitialRequest&postBackURL=http://localhost:3000/member/my-profile&storeId=15950&timeStamp=${currentTimeStamp()}`;

      let encryptedHashRequest = convertHashKey(
        "Z8FUQRKRZTKVU2Q2",
        tempURLparams
      );
      let params = `${url}storeId=15950&orderId=1222&transactionAmount=${topup_amount}&mobileAccountNo=&emailAddress=&transactionType=InitialRequest&tokenExpiry=&bankIdentificationNumber=&encryptedHashRequest=${encryptedHashRequest}&merchantPaymentMethod=&postBackURL=http%3A%2F%2Flocalhost%3A3000%2Fmember%2Fmy-profile&signature=`;
      setParams(params);

      window.location.href = params;
    }
  };

  const imgURL = "https://admin.gundogclub.pk/public/members/profile_pic/";

  return (
    <div className="col-md-12 my-2 px-0 ">
      <div className=" row mx-4">
        <div className="col-md-4">
          <div className="kennel-img">
            <img
              className="rounded-circle shadow-sm"
              src={user_data?.photo ? imgURL + user_data?.photo : placeHolder}
            />
          </div>
          <button
            data-toggle="modal"
            data-target="#editModal"
            className="text-left mt-3 btn-light px-2 py-1  border"
          >
            <i class="rounded-circle shadow fas text-dark fa-edit"></i> Edit my profile
          </button>
          
        </div>
        <div className="col-md-8 d-flex justify-content-start align-items-start">
          <h1 className="my-auto ">
            {user_data
              ? user_data?.first_name + " " + user_data?.last_name
              : null}
          </h1>
          <hr />
        </div>
        </div>
        <div className=" row mx-4">

        {delinquent ? 
        <><div className="col-md-12"></div>
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <h3 className="my-auto "><span className="badge bg-danger"><span className="badge bg-secondary">{'Rs. '+wallet}</span> Your account is delinquent. Please check your balance statement</span></h3>
            <hr />
          </div></>
        :
        ''
        }
        
      </div>
      <div className="user_data-bio mx-4 mt-5"></div>
      <Tabs>
        <TabList>
          <Tab>
            <i className="fa fa-user"></i> Profile
          </Tab>
          <Tab><i className="fas fa-paw"></i> Kennel</Tab>
          <Tab><i className="fas fa-venus-mars"></i> Breeding</Tab>
          <Tab>
            <i className="fas fa-dog"></i> Dogs
          </Tab>
          <Tab>
            <button
              data-toggle="modal"
              data-target="#topupModal"
              className="text-center mt-2 mx-5 btn-primary px-2 py-1  border"
            >
              Top Up Wallet
            </button>
          </Tab>
          <Tab>
          <button
            type="button"
            className="rounded shadow-sm border text-danger fw-bold butn butn-grey"
            onClick={() => {
              setAuthUser(null);
              localStorage.removeItem("user");
              navigate("/");
            }}
          >
            <i class="fas mx-2 fa-power-off"></i>
            Logout
          </button>
          </Tab>
        </TabList>
        <TabPanel>
          <div className="row mx-4 px-5 my-4 bg-light shadow-sm">
            <div className="col-md-6 d-flex">
              <div className="p-2">
                <b>First Name:</b>
              </div>
              <div className="p-2">{user_data?.first_name || "--"}</div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="p-2">
                <b>Last Name:</b>
              </div>
              <div className="p-2">{user_data?.last_name || "--"}</div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="p-2">
                <b>Email:</b>
              </div>
              <div className="p-2">{user_data?.email || "--"}</div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="p-2">
                <b>Membership No:</b>
              </div>
              <div className="p-2">{user_data?.membership_no || "--"}</div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="p-2">
                <b>Address:</b>
              </div>
              <div className="p-2">{user_data?.address || "--"}</div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="p-2">
                <b>Phone No.:</b>
              </div>
              <div className="p-2">{user_data?.phone || "--"}</div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          {
            kennels && kennels.length && kennels?.map(
            (item)=>
            (
              <Kennel data={item}/>
            )
            )
            ||
              <h5 className="m-4"> No kennels were found.</h5>
          }
        </TabPanel>
        <TabPanel>
        </TabPanel>
        <TabPanel>
          <div className="col-md-6">
            {(dogs && dogs?.map((item) => <ProgenyChild data={item} />)) || (
              <h5 className="m-4">No dogs were found.</h5>
            )}
          </div>
        </TabPanel>
      </Tabs>
      <EditProfile id={user?.id} />
      <TopUpModal />
    </div>
  );
};

export default MYProfile;
