import React, { useContext, useEffect, useState } from 'react'
import {Link, useParams} from 'react-router-dom';
import { getAllRules } from '../../api';
import { GlobalContext } from '../../context/store';
import './style.css'

const Sidebar_Rules = ({id}) => {

    const[rules,setRules]=useState([])

    useEffect(()=>{
        const fetchData=async()=>{
            const data=await getAllRules()
            setRules(data&&data)
        }

        fetchData()

    },[])


    return (
        <div className="side-menu">
        <ul className="mx-2 p-2 rounded">
            {
                rules&&rules?.map(({rule_name,friendly_url})=>(

                    <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={friendly_url?friendly_url:"#"}> {rule_name}</Link></li>
                ))
            }
  
            
        </ul>
    </div>
    )
}

export default Sidebar_Rules
