import aesjs from 'aes-js'

export function getUnique(array){
    var uniqueArray = [];
    
    // Loop through array values
    for(var value of array){
        if(uniqueArray.indexOf(value) === -1){
            uniqueArray.push(value);
        }
    }
    return uniqueArray;
}

// conversion of timestamp to token

export const dateConverterYYYYMMDDHHMMSS=()=>{
    function pad2(n) { return n < 10 ? '0' + n : n }
    var date = new Date(); 
    return date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2( date.getDate()) + pad2( date.getHours() ) + pad2( date.getMinutes() ) + pad2( date.getSeconds() ) 
}

/// encryption algo


export const convertHashKey=(key,content)=>{

const keyBuffer = aesjs.utils.utf8.toBytes(key);
const inputBuffer = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(content));
const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer);
const encryptedBytes = escEcb.encrypt(inputBuffer);
const encryptedData = Buffer.from(encryptedBytes).toString('base64');
return encodeURIComponent(encryptedData);
}

export const currentTimeStamp=()=>{
    let date=new Date()
    let time=date.toTimeString().slice(0,8)
    let c_date=new Date().toISOString().slice(0, 10)

    return c_date+"T"+time
}

