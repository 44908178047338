import React, { useEffect, useRef, useState } from "react";
import { getAllCities, getUserById, updateUserProfile } from "../api";
import Select from "react-select";
import { toast } from "react-toastify";

const EditProfile = ({ id }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [emailShow, setEmailShow] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [photo, setPhoto] = useState("");
  const [cnic, setCnic] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneShow, setPhoneShow] = useState("");
  const [address, setAddress] = useState("");
  const [addressShow, setAddresShow] = useState("");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [passShow, setShowPass] = useState(false);
  const [memberNo, setMemberNo] = useState("");
  const [cities, setCities] = useState([]);
  const { cityRef } = useRef(null);
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await getUserById(id);
      setUser(res && res[0]);
    };
    fetchData();
  },[id]);


  useEffect(()=>{
    setUserData();
  },[user])
  
  const setUserData = () => {
    setMemberNo(user?.membership_no);
    setFirst_name(user?.first_name);
    setlast_name(user?.last_name);
    setPhone(user?.phone);
    setEmail(user?.email);
    setCnic(user?.cnic);
    setCity(user?.city);
    setAddress(user?.address);
    setUsername(user?.username);
    setUsername(user?.username);
    setEmailShow(user?.check_email);
    setPhoneShow(user?.check_phone);
    setAddresShow(user?.check_address);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllCities();
      setCities(res && res);
    };
    fetchData();
  }, []);

  const onSub = async (e) => {
    var formdata = new FormData();
    formdata.append("city", city);
    formdata.append("first_name", first_name);
    formdata.append("last_name", last_name);
    formdata.append("phone", phone);
    formdata.append("email", email);
    formdata.append("address", address);
    formdata.append("cnic", cnic);
    formdata.append("new_photo", photo);
    formdata.append("pwdchange", password);
    formdata.append("password", "");
    formdata.append("check_email", emailShow);
    formdata.append("check_address", addressShow);
    formdata.append("check_phone",phoneShow);
    formdata.append("zip_code", "");
    formdata.append("membership_no", memberNo);
     e.preventDefault();
     const res=await updateUserProfile(id,formdata)

if(res.status==200){
  toast.success('Updated profile successfully', {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    },hideModal());
  }    
  };

  const hideModal=()=>{
    let el = document.getElementById('closeModal');
    if (el.onclick) {
       el.onclick();
    } else if (el.click) {
       el.click();
    }
  }
  



  return (
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-bold" id="editModalLabel">
              Edit Profile
            </h5>
            <button
              type="button"
              class="close btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body ">
            <div className="row">
              {/* <h1 className="">Online Membership Application</h1> */}
              <div className="col-md-12 col-md-offset-2 p-4">
                <form onSubmit={onSub}>
                  <div className="col-md-2">
                    <label for="firstName">Membership No</label>
                    <input
                      disabled
                      value={memberNo}
                      type="text"
                      className="form-control"
                      id="membership"
                      placeholder="Member Ship No."
                      onChange={(e) => setMemberNo(e.target.value)}
                    />
                  </div>
                  <div className="row my-2">
                    <div className="form-group col-md-6">
                      <label for="firstName">First Name</label>
                      <input
                        required
                        value={first_name}
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                        onChange={(e) => setFirst_name(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="Last Name">Last Name</label>
                      <input
                        required
                        value={last_name}
                        type="text"
                        className="form-control"
                        id="Lastname"
                        placeholder="Last Name"
                        onChange={(e) => setlast_name(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label for="PhoneNo">Phone No.</label>
                        <input
                          required
                          value={phone}
                          type="text"
                          className="form-control"
                          id="phoneNo"
                          placeholder="03xxxxxxxxx"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="p-2 d-flex mt-3">
                        <div class="form-check mx-5">
                          <input
                            defaultChecked={phoneShow === "Show"}
                            class="form-check-input"
                            type="radio"
                            name="phone-show"
                            id="phone-show"
                          />
                          <label class="form-check-label" for="phone-show">
                            Show Phone
                          </label>
                        </div>
                        <div class="form-check mx-5">
                          <input
                            defaultChecked={phoneShow === "Hide"}
                            class="form-check-input"
                            type="radio"
                            name="phone-show"
                            id="phone-show1"
                          />
                          <label class="form-check-label" for="phone-show1">
                            Hide Phone
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <label for="Email">Email</label>
                      <input
                        required
                        value={email}
                        type="email"
                        className="form-control"
                        id="Email"
                        placeholder="example@xyz.com"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="p-2 d-flex mt-3">
                        <div class="form-check mx-5">
                          <input
                            class="form-check-input"
                            defaultChecked={emailShow === "Show"}
                            type="radio"
                            name="email-show"
                            id="email-show"
                          />
                          <label class="form-check-label" for="email-show">
                            Show Email
                          </label>
                        </div>
                        <div class="form-check mx-5">
                          <input
                            class="form-check-input"
                            defaultChecked={emailShow === "Hide"}
                            type="radio"
                            name="email-show"
                            id="email-show1"
                          />
                          <label class="form-check-label" for="email-show1">
                            Hide Email
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="form-group col-md-6">
                      <label for="inputAddress">CNIC</label>
                      <input
                        required
                        value={cnic}
                        type="text"
                        className="form-control"
                        id="inputAddress"
                        placeholder="CNIC"
                        onChange={(e) => setCnic(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="inputCity">City</label>
                      <Select
                        ref={cityRef}
                        options={cities?.map(({ city, id }) => ({
                          value: id,
                          label: city,
                        }))}
                        onChange={(e) => setCity(e.value)}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="form-group col-md-12">
                      <label for="Address">Address.</label>
                      <input
                        value={address}
                        type="text"
                        className="form-control"
                        id="Address"
                        placeholder="Address"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="p-2 d-flex mt-3">
                        <div class="form-check mx-5">
                          <input
                            defaultChecked={addressShow === "Show"}
                            class="form-check-input"
                            type="radio"
                            name="address-show"
                            id="address-show"
                          />
                          <label class="form-check-label" for="address-show">
                            Show Address
                          </label>
                        </div>
                        <div class="form-check mx-5">
                          <input
                            defaultChecked={addressShow === "Hide"}
                            class="form-check-input"
                            type="radio"
                            name="address-show"
                            id="address-show1"
                          />
                          <label class="form-check-label" for="phone-show1">
                            Hide Address
                          </label>
                        </div>
                      </div>
                  </div>

                  <div className="my-3">
                    <input type="file" className="form-control"
                    onChange={(e)=>{setPhoto(e.target.files[0])}}
                    />
                  </div>
                  <hr />
                  <div className="row ">
                    <div className="col-md-12">
                      <h4 className="mt-4 text-success fw-bold">
                        Login Details
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="Address">User Name</label>
                            <input
                              disabled
                              value={username}
                              type="text"
                              className="form-control"
                              id="Address"
                              placeholder="Username"
                            />
                          </div>
                          <small className="text-muted">
                            * Alpha, numerics and underscores are allowed only.
                          </small>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className=" d-flex">
                              <label for="Address">Change Password</label>
                              <div class="form-check mx-5">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="pass-show"
                                  id="pass-show"
                                  onChange={() => {
                                    setShowPass(true);
                                  }}
                                />
                                <label class="form-check-label" for="pass-show">
                                  Yes
                                </label>
                              </div>
                              <div class="form-check mx-5">
                                <input
                                  defaultChecked
                                  class="form-check-input"
                                  type="radio"
                                  name="pass-show"
                                  id="pass-show1"
                                  onChange={() => {
                                    setShowPass(false);
                                  }}
                                />
                                <label
                                  class="form-check-label"
                                  for="pass-show1"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                            {passShow ? (
                              <input
                                value={password}
                                type="text"
                                className="form-control"
                                id="Address"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            ) : null}
                          </div>
                          <small className="text-muted">
                            Password must contain a lower case letter,
                            capital(uppercase) letter, number and minimum 12
                            digit. Note: If you would like to change the
                            password type a new one. Otherwise leave this blank.
                          </small>
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="closeModal"
            >
              Close
            </button>
            <button type="button" onClick={(e)=>{onSub(e)}} class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
