import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getBlogPostBySlug, getAllBlogs } from '../../../api'
import { Link } from 'react-router-dom'

const PostDetails = () => {
    const[post,setPost]=useState({})
    const [posts,setPosts]=useState([])
    const {slug}=useParams()

    const [marginTop, setMarginTop] = useState(0);

    function addHighlight() {
        const paragraphs = document.getElementsByTagName('table');
        const imgs = document.getElementsByTagName('img');
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i].classList.add('table');
            if(paragraphs[i].hasAttribute('width'))
            {
                paragraphs[i].removeAttribute('width');
            }
        }

        for (let i = 0; i < imgs.length; i++) {
            imgs[i].style.width = '80%';
            imgs[i].style.display = 'block';
        }
    }
    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getBlogPostBySlug(slug)
            setPost(res&&res[0])
            addHighlight();
        }
        fetchData()

        const fetchData2=async()=>{
            const res=await getAllBlogs()
            if(res&&res)
            {
                const res2 = res.filter(ress => ress.link !== slug);
                setPosts(res2&&res2)   
            }
        }
        fetchData2()
        
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        const handleScroll = () => {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            setMarginTop(scrollTop);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[slug])

    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }
    
    return (
        <div className="container mt-5">
            <div className="blog_content">
                <h1>{post?.title}</h1>
                <h6 className="fw-bold mb-4 mt-3">Posting Date:  {new Date(post?.publishing_date).toDateString()}</h6>
                <div dangerouslySetInnerHTML={{__html: convertHTMLEntity(post?.content)}} style={{justifyContent: 'center', display: 'grid'}}></div>
            </div>
            <div className="sidebar side-menu" style={{marginTop: `${marginTop}px`}}>
                <h1>Recent Articles</h1>
                <ul className="mx-2 p-2 rounded">
                    <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={posts?.[0]?.link ? '/blog/'+posts?.[0]?.link : '#'}>{posts?.[0]?.title}</Link></li>
                    <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={posts?.[1]?.link ? '/blog/'+posts?.[1]?.link : '#'}>{posts?.[1]?.title}</Link></li>
                    <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={posts?.[2]?.link ? '/blog/'+posts?.[2]?.link : '#'}>{posts?.[2]?.title}</Link></li>
                    <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={posts?.[3]?.link ? '/blog/'+posts?.[3]?.link : '#'}>{posts?.[3]?.title}</Link></li>
                    <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={posts?.[4]?.link ? '/blog/'+posts?.[4]?.link : '#'}>{posts?.[4]?.title}</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default PostDetails
