import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllEvents } from "../../api";
import "react-lazy-load-image-component/src/effects/blur.css";
import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      const res = await getAllEvents();
      setEvents(res && res);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    fetchEvents();
  }, []);

  const pageTransition = {
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: "-100vw",
    },
  };

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransition}
      className="mt-5 container"
    >
      <h1>GDCP EVENTS</h1>
      <div className="row">
        {loading
          ? [0, 1, 2, 3].map((a, ind) => (
              <div className="col-md-3 mb-3 pb-2" key={ind}>
                <Skeleton height={30} className="mb-2" />
                <Skeleton height={15} count={3} width={"60%"} />
                <Skeleton height={25} width={"70%"} />
                <div className="row justify-content-start mt-3">
                  <div className="col-md-12">
                    <Skeleton height={40} className=" btn" />
                  </div>
                </div>
              </div>
            ))
          : events
              ?.reduce((arr, item) => {
                const filtered = arr.filter((i) => i["id"] !== item["id"]);
                return [...filtered, item];
              }, [])
              .map(
                ({
                  title,
                  start_date,
                  venue,
                  club_judge,
                  end_date,
                  friendly_URL,
                  visit_judge,
                }) => (
                  <div className="col-md-3">
                    <div className="mt-4">
                      <div
                        className="card bg-light shadow-sm"
                        style={{ width: "18rem", minHeight: "360px" }}
                      >
                        <div className="card-body">
                          <h5 className="fw-bold my-3">{title}</h5>
                          <hr></hr>
                          <span className="d-block my-2">
                            <label className="fw-bold mx-2">Date:</label>
                            {new Date(start_date)
                              .toLocaleString("en", {
                                timeZone: "Asia/Karachi",
                                month: "short",
                                weekday: "short",
                                year: "numeric",
                                day: "numeric",
                              })
                              .replaceAll(",", "")}
                          </span>
                          <span className="d-block my-2">
                            <label className="fw-bold mx-2">Venue:</label>{" "}
                            {venue}
                          </span>
                          <span className="d-block my-2">
                            <label className="fw-bold mx-2">Judge(s):</label>
                            {visit_judge ? visit_judge : ""}{" "}
                            {club_judge ? club_judge : ""}
                          </span>
                          <hr></hr>
                          {new Date(end_date) <= new Date() ? (
                            ''
                          ) : (
                            <Link
                              to={`/member/show-entry/${friendly_URL || "#"}`}
                              className="btn btn-success w-100 shadow-sm"
                            >
                              Entries are Open
                            </Link>
                          )}
                          <a
                            href={`https://admin.gundogclub.pk/admin/shows/pdf/${friendly_URL || "#"}.pdf`}
                            className="btn mt-2 btn-primary w-100 shadow-sm"
                          >
                            View Catalog
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
      </div>
    </motion.div>
  );
};

export default Events;
