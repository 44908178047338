import React from "react";
import Select from "react-select";

const CreateStudCertificate = () => {
  return (
    <div>
      <h1>Online Stud Certificate</h1>
      <span className="p-1 badge badge-primary  bg-success">
        Current Balance : 12,454.00
      </span>
      <p className="py-3">
        By filling out this online stud certificate, I certify that the
        information contained in is correct to the best of my knowledge and
        understanding. Furthermore, I have also informed my local Group Breed
        Warden of this mating.
      </p>
      <div className="bg-light shadow-sm">
        <div className="row d-flex justify-content-around align-items-center">
          <div className="col-md-5 shadow-sm bg-white p-2 my-2 rounded">
            <label className="text-dark">
              <b>Name of Dog:</b>
            </label>
            <Select options={[]} />
          </div>
          <div className="col-md-5 shadow-sm bg-white p-2 my-2 rounded">
            <label className="text-dark">
              <b>Name of Bitch:</b>
            </label>
            <Select options={[]} />
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center p-3">
          <button className="shadow-sm mx-4 btn btn-danger">Submit</button>
        </div>
        <h2 className="px-3 ">STUD CERTIFICATE</h2>
        <p className="text-justify p-3">
          Once you click Submit, this certificate will be recorded. An online
          copy of this certificate will also be available to the owner of the
          bitch. If you already have a positive balance in your account, that
          balance will be used for payment. If payment is required, you will be
          alerted of the amount payable. All payments are only acceptable in the
          form of a Bank Draft or Pay Order, drawn on a local bank and made out
          in favor of “ GDCP ". No payments made via any other
          method are acceptable.
        </p>
        <div className="p-3">
        <div className="card-basic ">
          <div className="card-header header-basic">
            <h4>Stud Certificate Fee</h4>
          </div>
          <div className="card-body">
            <p>
              {" "}
              Rs. <h2>100</h2> Certificate
            </p>
         
          </div>
        </div>
        </div>
      
      </div>
    </div>
  );
};

export default CreateStudCertificate;
