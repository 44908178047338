import React, { useState, useEffect } from "react";
import { memberSearch, getAllMembers } from "../../api";
import placeHolder from "../../assets/Images/userPlaceholder.png";
import { getUnique } from "../../utils";
import loader from "../../assets/Images/loader.gif";
import logo from "../../assets/Images/website_logo_large-300x241.png";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {motion} from 'framer-motion';

const Members = () => {
  const [MemberData, setMemberData] = useState([]);
  const [searchLetters, setLetters] = useState([]);
  const [Member, setMember] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const data = await getAllMembers();
    setMember(data);
    let letter_arr = [];
    if (MemberData) {
      for (let i of MemberData) {
        letter_arr.push(i?.first_name?.charAt(0));
      }
    }
    setLetters(getUnique(letter_arr));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(async () => {
    setMemberData(Member);
    let letter_arr = [];
    if (MemberData) {
      for (let i of MemberData) {
        letter_arr.push(i?.first_name?.charAt(0));
      }
    }
    setLetters(getUnique(letter_arr));
  }, [Member]);

  const filterByFirstLtr = (letter) => {
    setLoading(true);
    if (letter == "*") {
      setMemberData(Member);
    } else {
      const data = Member?.filter(
        (data) => data.first_name.charAt(0) == letter
      );
      console.log(data);
      setMemberData(data);
    }
    setLoading(false);
  };

  const handleChange = async (e) => {
    setLoading(true);
    let result = await memberSearch(e);
    if (e === "") {
      setMemberData(Member);
      let letter_arr = [];
      if (MemberData) {
        for (let i of MemberData) {
          letter_arr.push(i?.first_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
    } else {
      setMemberData(result);
      let letter_arr = [];
      if (MemberData) {
        for (let i of MemberData) {
          letter_arr.push(i?.first_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
    }
    setLoading(false);
  };

    const pageTransition={
      in:{
          opacity:1,
          x:0
      },
      out:{
          opacity:0,
          x:'-100vw'
      }
  }

  return (
      <motion.div 
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransition}
      className="row mx-0 bg-white rounded p-2">
      {/* <div className="col-md-2  rounded">
      <div className="logo">
        <img className="" src={logo} />
      </div>
      <hr />

    </div> */}
      <div className="col-md-12 my-2 px-0 ">
        <>
        <div className="container mt-3">
            <h1>Gundog Club of Pakistan Members</h1>
        </div>
          <div className="container mt-3 w-100 d-flex justify-content-end px-2">
            <input
              type="search"
              className="form-control  search-input w-50  my-3 mx-3"
              PlaceHolder="Search by Kennel Name , User Name, Suffix , Prefix and City"
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
          <div className="container  w-100 flex-wrap d-flex justify-content-end px-2 ">
            <button
              onClick={() => {
                filterByFirstLtr("*");
              }}
              className="filterBtn my-2   btn shadow-sm fw-bold border"
              >
              All
            </button>
            {searchLetters &&
              searchLetters.sort().map((item, ind) => (
                <button
                  key={ind}
                  onClick={() => {
                    filterByFirstLtr(item);
                  }}
                  className="filterBtn my-2   btn shadow-sm fw-bold border"
                  >
                  {item}
                </button>
              ))}
            {/* <button
          onClick={() => {
            filterByFirstLtr("asc");
          }}
          className="filterBtn my-2 "
        >
          A - Z
        </button>
            <button
          onClick={() => {
            filterByFirstLtr("dec");
          }}
          className="filterBtn my-2 "
        >
          Z - A
        </button> */}
          </div>
          <div className="row mx-auto container justify-content-center">
            {loading
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((a, ind) => (
                  <div className="col-md-3 my-2" key={ind}>
                    <div className="row">
                      <div className="rounded-circle col-md-4">
                        <Skeleton
                          className="rounded-circle shadow border"
                          height={125}
                          width={125}
                        />
                      </div>
                      <div
                        className="rounded-circle col-md-8"
                        style={{ zIndex: 0, marginTop: "8%" }}
                      >
                        <Skeleton
                          className=" shadow"
                          style={{
                            borderTopRightRadius: 50,
                            borderBottomRightRadius: 50,
                          }}
                          height={45}
                          width={"100%"}
                        />
                        <Skeleton
                          className=" shadow mt-3"
                          style={{
                            borderTopRightRadius: 50,
                            borderBottomRightRadius: 50,
                          }}
                          height={10}
                          width={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                ))
              : MemberData && MemberData
              ? MemberData?.map(
                  ({
                    id,
                    first_name,
                    friendly_url,
                    image,
                    last_name,
                    photo,
                    membership_no,
                  }) => (
                    <div className="col-md-4">
                      <Link
                        to={`/member-profile/${friendly_url}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="d-flex rounded  my-2"
                          style={{ minWidth: "20rem" }}
                        >
                          <div className="profile-img shadow rounded-circle">
                            {photo? (
                              <LazyLoadImage
                                alt={""}
                                className="rounded-circle border"
                                height={130}
                                effect="blur"
                                src={
                                  "https://admin.gundogclub.pk/public/members/profile_pic/" +
                                  photo
                                } // use normal <img> attributes as props
                              />
                            ) : (
                              <img
                                className="card-img-top"
                                src={placeHolder}
                                alt="Card image cap"
                                className="rounded-circle"
                                height={130}
                                width={150}
                                style={{zIndex:'auto'}}
                              />
                            )}
                          </div>
                          <div
                            className="card-body p-0 "
                            style={{ marginLeft: "-10%", marginTop: "5%" ,   zIndex:-1}}
                          >
                            <div
                              className="shadow px-3 p-1 border"
                              style={{
                                minHeight: 90,
                                borderTopRightRadius: 50,
                                borderBottomRightRadius: 50,
                             
                              }}
                            >
                              <h5 className="fw-bold card-title mb-1 mx-2 px-4">
                                {first_name + " " + last_name}
                              </h5>
                              <span
                                className="card-title fw-bold mx-2 px-4"
                                style={{ color: "grey" }}
                              >
                                Membership #: <span className="fw-normal">{membership_no}</span>
                              </span>
                            </div>

                            {/* <a href="#" className="btn-grad">Go somewhere</a> */}
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                )
              : null}
          </div>
        </>
      </div>
      <div className="col-md-2  rounded justify-content-center align-items-center">
        {/* <img className="rounded mt-2 w-100" src={sideimg} /> */}
      </div>
    </motion.div>
  );
};

export default Members;
