import React, { useState } from 'react'
import { useParams } from 'react-router'
import { getAllDogOwners } from '../../api';

const DogOwners = () => {

    const {slug}=useParams()
    const [owners,setOwners]=useState([]);

    useState(()=>{

        const fetchData=async()=>{
            const res=await getAllDogOwners(slug)
            setOwners(res&&res)
        }
        fetchData()

    },[slug])

    return (
        <div className="container mt-5">
            <h1>Ownership Transfer Lease</h1>
            <div className="container mt-3 p-3  ">
                <h3 className=" fw-bold">Transfer/Lease of <span className="text-primary">{owners[0]?.dog_name}</span></h3>
                {
                   owners&&owners?.map(({address,email,phone,type,username})=>(
                       <div className="col-md-8">
                <div className="bg-light p-4 row  shadow-sm border my-3">
                        <div className="col-md-12 d-flex align-items-center flex-wrap bg-white rounded shadow-sm mb-2">
                            <h2 className="p-2 mb-0">{username}</h2><span className="badge btn-primary m-2">{type}</span>
                        </div>
                        <div className="col-md-4">
                            <label>Phone:</label>
                        </div>
                        <div className="col-md-8">
                            <h6>{phone||"-"}</h6>
                        </div>
                        <div className="col-md-4">
                            <label>Address:</label>
                        </div>
                        <div className="col-md-8">
                            {address||"-"}
                        </div>
                        <div className="col-md-4">
                            <label>Email:</label>
                        </div>
                        <div className="col-md-8">
                            {email||"-"}
                        </div>
                    </div>
                       </div>
                
                   )) 
                  
                }
            </div>
        </div>
    )
}

export default DogOwners