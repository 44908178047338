import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/Images/Logo.png'
import { GlobalContext } from '../../context/store'

const MobileTopBar = () => {
    const {user}=useContext(GlobalContext)
    return (
        <div className='nav-top-bar-mobile shadow'>
            <div className='container-fluid d-flex justify-content-between align-items-center'>
                <div className='mobile-logo '>
                    <Link to="/" className='shadow '>
                <img src={logo}/>
                    </Link>
                </div>
                {/* <h6 className='mob-header-heading align-middle text-center'>

                   Gundog Club of Pakistan
                </h6> */}
                <div className='profile-status'>
                    {
                        user?
                    <Link to="/member/my-profile">
                        <i class="far fa-user-circle"></i>
                    </Link>:
                    <Link to="/login" className='text-center'>
                       <i class="fas fa-sign-in-alt d-block"></i>Login
                   </Link>
                    }
                    
                 
                
                </div>
            </div>
        </div>
    )
}

export default MobileTopBar
