import React, { useContext } from 'react'
import {Link, useParams} from 'react-router-dom';
import { GlobalContext } from '../../context/store';

const Sidebar_About = ({id}) => {
    return (
        <div className="side-menu ">
        <ul className="mx-2 p-2 rounded">
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/about`}> About GDCP </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`subscription`}>Subscription & Fee Structure</Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/rules/code-of-ethics`}> Rules & Regulations </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`become-a-member`}>Membership Application </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`gdcp-team`}> The GDCP Team</Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`gdcp-judges`}> GDCP Judges</Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`visiting-judges`}>Visiting Judges</Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`news-and-updates`}> News & Updates </Link></li>
        </ul>
    </div>
    )
}

export default Sidebar_About
