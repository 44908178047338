import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { getAllMembers, getAllDogsByOwner, leaseDogOwnership, transferDogOwnership, removeDogOwnership } from "../../api";
import { GlobalContext } from "../../context/store";

export const CreateLeaseTransfer = () => {
  const [transferType, setTransferType] = useState("");
  const [members, setMembers] = useState([]);
  const [dogs, setDogs] = useState([]);
  const [member, setMember] = useState('');
  const [dog, setDog] = useState('');
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromData] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [ownnerId, setOwnerId] = useState('');
  const [message, setMessage] = useState('');
  const transferTypeRef = useRef(null)
  const dogRef = useRef(null)
  const memberRef = useRef(null)

  const{user}=useContext(GlobalContext)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllMembers();
      setMembers(res && res);
      console.log("api call ");
    };
    if (
      (transferType == "Transfer" && !members?.length) ||
      (transferType == "Lease" && !members?.length)
    ) {
      fetchData();
    }
    const fetchOwners = async () => {
     
      const {id} = user;
      setOwnerId(id)
      const res = await getAllDogsByOwner(id&&id);
      setDogs(res && res);
    };
    fetchOwners();
  }, [transferType]);



  function toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    console.log(local.toJSON().slice(0, 10))
    return local.toJSON().slice(0, 10);
  }
  const onSubmitHandler=async()=>{

    if(transferType=="Lease"){
      if(!dog || !toDate || !fromDate || !member){
        setMessage("Please fill out all feilds!")
        setTimeout(() => {
          setMessage("")
        }, 3000);
      }else{
        const data={
          dogId: dog,
          memberId:member,
          toDate:toJSONLocal(new Date(toDate)),
          fromDate:toJSONLocal(new Date(fromDate)),
          remarks:null
        }
        const res=await leaseDogOwnership(data)
        if(res){
          transferTypeRef.current.clearValue()
          dogRef.current.clearValue()
          setMessage("Lease Transfered !")
          setTimeout(()=>{setMessage("")},4000)
        }
        }
    }
    else if(transferType=="Transfer"){
     
      if(!member || !dog){
        setMessage("Please fill out all feilds!")
        setTimeout(() => {
          setMessage("")
        }, 3000);
      }
      else{
        const data={
          dogId: dog,
          ownerId:ownnerId,
          memberId:member,
          remarks:null
        }
        const res=await transferDogOwnership(data)
        if(res){
          transferTypeRef.current.clearValue()
          dogRef.current.clearValue()
          // memberRef.current.clearValue()
          setMessage("Ownership Transfered !")
          setTimeout(()=>{setMessage("")},4000)
        }
        
      }

    }
    else if(transferType=="Remove"){
      if(!dog){
        setMessage("Please fill out all feilds!")
        setTimeout(() => {
          setMessage("")
        }, 3000);
      }
      else{
        const data={
          dogId: dog,
          ownerId:ownnerId
        }
        console.log(data,"data")
        const res=await removeDogOwnership(data)
        if(res){
          transferTypeRef.current.clearValue()
          dogRef.current.clearValue()
          setMessage("Ownership Removed !")
          setTimeout(()=>{setMessage("")},4000)
        }
      }
    }
    else{
      return;
    }
  }



  return (
    <div>
      <h1 className="mb-4">Ownership Transfer Lease</h1>
      <h4>
        Dog Ownership Transfer Fee is waived in case of a breeder transferring
        ownership of a puppy.
      </h4>
      <p>
        Once you click Submit, the ownership of the dog will be transferred to
        the new owner. If you already have a positive balance in your account,
        that balance will be used for payment. If payment is required, you will
        be alerted of the amount payable. All payments are only acceptable in
        the form of a Bank Draft or Pay Order, drawn on a local bank and made
        out in favour of “GDCP“. No payments made via any other
        method are acceptable.
      </p>
      <h4>Ownership Transfer</h4>
      <p>
        By filling out this online ownership transfer form, I hereby declare
        that the ownership of the said dog has been transferred from myself to
        the new owner.
      </p>
      <h4>Dog Lease</h4>
      <p>
        By filling out this online lease form, I hereby declare that I, being
        the owner of the dog/bitch have leased it to said GSDCP member. I also
        certify that I have made a formal agreement with the lessee. I also
        acknowledge and understand that this lease form does not constitute a
        formal agreement between myself and the lessee.
      </p>
      <div className="bg-light shadow-sm border my-2">
        <div className="d-flex">
          <div className=" w-25 p-3">
            <div className="">
              <label htmlFor="Dog" className="fw-bold">
                Transfer Type{" "}
              </label>
              <Select
               ref={transferTypeRef}
                options={[
                  { label: "Transfer Ownership", value: "Transfer" },
                  { label: "Remove Ownership", value: "Remove" },
                  { label: "Lease", value: "Lease" },
                ]}
                onChange={(e) => setTransferType(e?.value)}
              />
            </div>
          </div>
          <div className=" w-25 p-3">
            <div className="">
              <label htmlFor="Dog" className="fw-bold">
                Name of Dog :{" "}
              </label>
              <Select
                options={dogs?.map((dog) => ({
                  label: dog.dog_name,
                  value: dog.dog_id,
                }))}
                ref={dogRef}
                onChange={
                  
                  (e)=>{
                    setDog(e?.value)
                   console.log(e?.value)}
                }
              />
            </div>
          </div>
        </div>

        <div className="row w-100 p-3">
          {transferType === "Transfer" || transferType === "Lease" ? (
            <div className="col-md-4 w-25">
              <label htmlFor="Dog" className="fw-bold">
                Select Member:{" "}
              </label>
              <Select
                options={members?.map((item) => ({
                  label:
                    item.first_name +
                    " " +
                    item.last_name +
                    " - " +
                    item.membership_no,
                  value: item.id,
                }))}
                onChange={(e) => setMember(e.value)}  
                ref={memberRef}
              />
            </div>
          ) : null}
          {transferType === "Lease" ? (
            <>
              <div className="col-md-4 w-25">
                <label htmlFor="Dog" className="fw-bold">
                  Duration of Lease:{" "}
                  <span className="text-success">Start Date:</span>{" "}
                </label>
                <input type="date" className="form-control" onChange={(e)=>setToDate(e.target.value)} />
              </div>
              <div className="col-md-4 w-25">
                <label htmlFor="Dog" className="fw-bold">
                  Duration of Lease:{" "}
                  <span className="text-danger"> End Date:</span>{" "}
                </label>
                <input type="date" className="form-control" onChange={(e)=>setFromData(e.target.value)} />
              </div>
            </>
          ) : null}
        </div>

        <div className=" w-50 p-3">
          <div className="">
            <label htmlFor="Dog" className="fw-bold">
              Remarks ( optional )
            </label>
            <textarea onChange={(e)=>{setRemarks(e.target.value); console.log(e.target.value)}} className="form-control"></textarea>
          </div>
        </div>

        <input
          type="button"
          className="butn shadow-sm rounded my-3 mx-3"
          value="Submit"
          onClick={()=>onSubmitHandler()}
        />
        {
          message?
          <div class="alert alert-info mx-3" role="alert">
            {message}
          </div>:null
        }

      </div>
      <p className="mt-5">
        Once you click Submit, the the dog will be marked as leased to the new
        owner. If you already have a positive balance in your account, that
        balance will be used for payment. If payment is required, you will be
        alerted of the amount payable. All payments are only acceptable in the
        form of a Bank Draft or Pay Order, drawn on a local bank and made out in
        favour of “GDCP“. No payments made via any other method are
        acceptable.
      </p>
      <div className="d-flex">
        <div className="p-3">
          <div className="card-basic ">
            <div className="card-header header-basic">
              <h4>Dog Ownership Transfer Fee</h4>
            </div>
            <div className="card-body">
              <p>
                {" "}
                Rs. <h2>1,000</h2> Transfer
              </p>
            </div>
          </div>
        </div>
        <div className="p-3">
          <div className="card-basic ">
            <div className="card-header header-basic">
              <h4>Dog Lease Fee</h4>
            </div>
            <div className="card-body">
              <p>
                {" "}
                Rs. <h2>1,000</h2> Lease
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLeaseTransfer;
