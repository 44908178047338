import React, { useEffect, useState } from 'react'
import { DOG_BREED } from '../constants'
import placeHolder from "../assets/Images/placeholderdog.jpg";
import { Link } from 'react-router-dom';

const ProgenyChild = ({data}) => {
  const [dog,setDog]=useState({})
  useEffect(() => {
    setDog(data)
  },[data])

    return (
      <div className="row px-4">
        <div className="col-md-12">
          <div className="row py-2 mt-3 mx-0 sibblings-dog bg-white border rounded shadow-sm ">
            <div className="col-3">
              <img src={placeHolder} className="progeny-child-dog-img rounded" />
            </div>
            <div className="col-9 d-flex flex-column justify-content-center ">
              <div className="d-flex flex-column justify-content-between mx-2">
                <h4 className="txt-primary"><Link style={{textDecoration:'none'}} to={`/dog/bio/${dog?.friendly_URL}`}>{dog?.dog_name}</Link> </h4>
                <h6>
                  {" "}
                  <i className={`fas ${dog&&dog?.sex=="Female"?"fa-venus":dog&&dog?.sex=="Male"?"fa-mars":null}`}></i>
                  {dog.sex} | {DOG_BREED}
                </h6>
                <div className="d-flex justify-content-between">
                  <span className="txt-primary">
                    <b>Whelped:</b>
                  </span>{" "}
                  <span>{dog?.dob?.slice(0,10)}</span>
                  <span className="text-warning">
                    <b>
                      <i>Registry:</i>
                    </b>
                  </span>{" "}
                  <span>{dog?.regestration_no || dog.KP}</span>
                </div>
              </div>
            </div>
          </div>
         
        </div>
    </div>
    )
}

export default ProgenyChild
