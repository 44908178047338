import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllVisitingJudges, getTeam } from '../../../api'
import placeHolder from'../../../assets/Images/userPlaceholder.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {motion} from 'framer-motion'

const VisitigJudges = () => {
    const[team,setTeam]=useState([])

    useEffect(()=>{

        const fetchData=async()=>{
            const res=await getAllVisitingJudges()
            setTeam(res&&res)
        }

    fetchData()
    },[])
    const imgURL="https://admin.gundogclub.pk/website_images/visit_judge_images/";
    const pageTransition={
        in:{
            opacity:1,
            x:0
        },
        out:{
            opacity:0,
            x:'-100vw'
        }
    }

    return (
        <motion.div 
        initial="out"
        animate="in"
        exit="out"
        variants={pageTransition}className="container">
            <h1>Visiting Judges</h1>
            <div className="row">
            {
                team&&team.length?team?.map(({full_name,description,credentials,image,url_link,facebook_url,youtube_url,twitter_url})=>(
                    <div class="col-md-4 container mt-4 mb-4 p-3 d-flex justify-content-center">
                    <div class="p_card p-4 shadow bg-white">
                        <div class=" image d-flex flex-column justify-content-center align-items-center"> 
                        {
                            image?
                    <LazyLoadImage
                    alt={full_name}
                    effect="blur"
                    Height={'200px'}
                    width={'200px'}
                    aiamgelt="Card image cap "
                    className="card-img-top p-2 rounded-circle shadow mb-2" 
              
                    src={imgURL+image||placeHolder} />:
                    <img className="card-img-top p-2 rounded-circle shadow mb-2" src={placeHolder} 
                    alt={full_name} aiamgelt="Card image cap " style={{maxHeight:200, width:'auto'}}/>
                        }
                  


                        {/* <img className="shadow rounded-circle" src={imgURL+image||placeHolder} height="150" width="158" /> */}
                        <span class="name mt-3 text-center">{full_name}</span> <span class="idd">{credentials}</span>
                           
                             {
                                description?
                                <div class=" d-flex mt-2"> 
                                 <Link to={url_link||"#"} className="btn  butn-grey shadow-sm">Read Bio</Link>
                             </div>:null
                             }
                         <div class="gap-3 mt-3 icons d-flex flex-row justify-content-center align-items-center">
                             {
                                 facebook_url?
                                 <a href={facebook_url||"#"}><i class="fab fa-facebook"></i></a>
                                 :null
                             } 
                             {
                                 youtube_url?
                                 <a href={youtube_url||"#"}><i  class="fab fa-youtube"></i></a>
                                 :null
                             }

                             {
                                 twitter_url?
                                 <a href={twitter_url||"#"}><i class="fab fa-twitter"></i></a> 
                                 :null

                             }
                        </div>
                        </div>
                    </div>
                </div>          

                )):null
            }
            </div>
          
        </motion.div>
    )
}

export default VisitigJudges
