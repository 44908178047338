import React, { useState, useEffect, useContext } from "react";
// import logo from '../../../assets/Images/website_logo_large-300x241.png';
import placeHolder from '../../../assets/Images/image_paceholder.png';
import { GlobalContext } from "../../../context/store";
import { useParams } from "react-router";
import { getAllDogsByOwner, getKennelsByOwner, getMemberBySlug, getUserById } from "../../../api";
import ProgenyChild from "../../../components/progeny_child";
import Kennels from "../../Kennels";
import Kennel from "../../../components/kennel";
import Loader from "../../../components/loader";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {motion} from 'framer-motion'
const MembersProfile= () => {
  const [user_data,setUserData]=useState({})
  const [kennels,setKennels]=useState([])
  const [dogs,setDogs]=useState([])
  const [loading,setLoading]=useState(false)

  const {slug}=useParams()

  const {user}=useContext(GlobalContext)

  useEffect(() => {
    const fetchMember=async()=>{
      setLoading(true)
      const res=await getMemberBySlug(slug)
      setUserData(res&&res[0])
    }
    fetchMember()
  }, [slug])
  
  useEffect(()=>{
    setLoading(true)
    const fetchData=async()=>{
      const kenl_res=await getKennelsByOwner(user_data?.id)
      setKennels(kenl_res)
      const dogs_res=await getAllDogsByOwner(user_data?.id)
      if(dogs_res?.length){
        setDogs(dogs_res)
      }
        setLoading(false)
    }
    fetchData()
  },[user_data])
  
  const pageTransition={
    in:{
        opacity:1,
        x:0
    },
    out:{
        opacity:0,
        x:'-100vw'
    }
}
  return (
    <motion.div 
    initial="out"
    animate="in"
    exit="out"
    variants={pageTransition}
className="row mx-0 bg-white rounded p-2 justify-content-center ">
      <>
    {/* <div className="col-md-2  rounded">
    </div> */}
    <div className="col-md-8 my-2 px-0 ">
       {
         loading?<Loader size={50}/>:
         <>
           <div className=" row mx-4">
        <div className="col-md-4">
            <div className="kennel-img">
            <LazyLoadImage
                   alt={""}
                   height={200}
                   effect="blur"
                   className="rounded-circle"
                   src={
                    user_data&&user_data?.photo?
                    "https://admin.gundogclub.pk/public/members/profile_pic/"+user_data?.photo
                    :placeHolder
                   } // use normal <img> attributes as props
                  />
                {/* <img className="rounded-circle shadow-sm" 
                src={user_data&&user_data?.photo?
                  "https://admin.gundogclub.pk/public/members/profile_pic/"+user_data?.photo
                  :placeHolder}/> */}
            </div>
        </div>
        <div className="col-md-8 d-flex justify-content-start align-items-start">
        <h1 className="my-auto ">{user_data?user_data?.first_name +" "+user_data?.last_name:null}</h1>
        <hr/>
        </div>
    </div>
    <div className="user_data-bio mx-4">
 <Tabs>
    <TabList>
      <Tab><i className="fas fa-user"></i> Profile</Tab>
      {/* <Tab><i className="fas fa-paw"></i> Kennel </Tab>
      <Tab>  <i className="fas fa-venus-mars"></i> Breeding</Tab> */}
      <Tab><i className="fas fa-dog"></i> Dogs</Tab>
    </TabList>
    <TabPanel>
  <div className="row mx-4 bg-light shadow-sm">
        <div className="col-md-6 d-flex">
            <div className="p-2"><b>First Name:</b></div>
            <div  className="p-2">{user_data?.first_name  || "--"}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Last Name:</b></div>
            <div  className="p-2">{user_data?.last_name  || "--"}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Email:</b></div>
            <div  className="p-2">{user_data?.email || "--"}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Membership No:</b></div>
            <div  className="p-2">{user_data?.membership_no  || "--"}</div>
        </div>
   
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Address:</b></div>
            <div  className="p-2">{user_data?.address  || "--"}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Phone No.:</b></div>
            <div  className="p-2">{user_data?.phone  || "--"}</div>
        </div>
   
  </div>
    </TabPanel>
    {/* <TabPanel>
    {
   kennels&&kennels.length&&kennels?.map((item)=>(
    <Kennel data={item}/>
      ))||<h5 className="m-4"> No kennels were found.</h5>
    }
    </TabPanel> */}
    {/* <TabPanel>
    </TabPanel> */}
    <TabPanel>
    {
      dogs&&dogs?.map((item)=>(
        <ProgenyChild data={item}/>
      ))||<h5 className="m-4">No dogs were found.</h5>
    }
        </TabPanel>
        </Tabs>
    </div></>
          }
    </div>
    {/* <div className="col-md-2  rounded justify-content-center align-items-center">
    </div> */}
    </>
  </motion.div >
  
  );
};

export default MembersProfile;
