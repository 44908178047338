import React from 'react'
import {Link} from 'react-router-dom'
import BannerImg from '../assets/Images/GC-home.jpg'
const Banner = () => {

    const user=localStorage.getItem('user')

    return (
        <div className="banner-main">
            <div className="banner-content d-flex flex-column justify-content-start align-items-start">
                <div className="banner-desc my-5 d-flex flex-column justify-content-start align-items-start">
                    <h1 className="text-left">Welcome To The Gundog Club</h1>
                    {/* <h4 className="text-left mt-3">Graded Training For Gundog Breeds.</h4> */}
                </div>
                <div className="banner-btns ">
                <Link to="/become-a-member" className="butn">Become A Member</Link>
                {
                    user?.length > 0 ?
                    <Link to="/member/my-profile" className="butn">My Profile</Link>:<Link to="login" className="butn">Login</Link>
                }
             
                </div>
               
            </div>
            <img  src={BannerImg}/>
        </div>
    )
}

export default Banner;
