import React from 'react'
import placeHolder from "../assets/Images/placeholder.jpg";

const Kennel = ({data}) => {
    return (
        <div className="col-md-4">
        <a href="#" className="text-decoration-non">
        <div className="d-flex border justify-content-around align-items-center p-2 w-100 shadow-member member-profile-main">
          <div className=" bordered">
            <img className="member-profile-img" src={placeHolder}/>
          </div>
          <div className="member-profile-description">
            <h5 className="text-center">{data?.kennel_name}</h5>
          </div>
        </div>
      </a>
      </div>
    )
}

export default Kennel
