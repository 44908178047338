import React, { useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { getAllBlogs } from '../api'
import BlogPost from './blog_post'
import { Link } from 'react-router-dom'

const MobileBlog = () => {
    const [posts,setPosts]=useState([])

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getAllBlogs()
            setPosts(res&&res)
        }
        fetchData()
    },[])

    return (
        <div className="mobile-blog-main p-3 rounded">
            <h1 className='mobile-blog-heading'>NEWS & EVENTS</h1>
            <h6 className='mobile-blog-sub-heading'>Stay up to date on our latest articles, press releases, and what we are doing to serve and bring change to our community.</h6>
            {
                posts&&posts.length&&posts?.slice(0,3)?.map(({title,link,preview_text})=>(
                    <div className='mobile-blog-post py-2'>
                        <Link to={"/blog/"+link||'#'}>
                        <h3 className='mobile-blog-post-title'>{title}</h3>
                        <p className='mobile-blog-post-preview_text'>{preview_text}</p>
                        </Link>
                    </div>
                ))
            }
      </div>
    )
}

export default MobileBlog;

