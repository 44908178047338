import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import { getAllEvents } from '../../api'

const ShowEntries = () => {
    const [events,setEvents]=useState([])

    useEffect(() => {
        const fetchEvents=async()=>{
            const res=await getAllEvents()
            setEvents(res&&res)
        }
        fetchEvents()

    }, [])


    return (
        <div>
            <h1>GDCP Shows Entries</h1>
            <div className="row">
                {
                   events?.reduce((arr, item) => {
                    const filtered= arr.filter(i => i['id'] !== item['id']);
                    return [...filtered, item];
                        }, []).map(({title,start_date,end_date,venue,club_judge,friendly_URL,visit_judge})=>(
                        <div className="col-md-3">
                        <div className="mt-4">
                            <div className="card bg-light shadow-sm" style={{width: "18rem"}}>
                                <div className="card-body">
                                    <h5 className="fw-bold my-3">{title}</h5>
                                    <hr></hr>
                                    <span className="d-block my-2"><label className="fw-bold mx-2">Date:</label>{start_date.slice(0,10)}</span>
                                    <span className="d-block my-2"><label className="fw-bold mx-2">Venue:</label> {venue}</span>
                                    <span className="d-block my-2"><label className="fw-bold mx-2">Judge(s):</label>{visit_judge?visit_judge: ""} {club_judge?club_judge: ""}</span>
                                    <hr></hr>
                                {
                                   new Date(end_date)<=new Date()?
                                   '':
                                   <Link to={`/member/show-entry/${friendly_URL||'#'}`} className="btn btn-success w-100 shadow-sm" style={{ marginBottom:10 }}>Entries are Open</Link>
                                }
                                    <Link to={`https://admin.gundogclub.pk/admin/shows/pdf/${friendly_URL || "#"}.pdf`} className="btn btn-primary w-100 shadow-sm">View Catalog</Link>
                                </div>
                            </div>
                    </div>
                        </div>
                    ))
                }
             
            </div>
            
        </div>
    )
}

export default ShowEntries
