import React, { useEffect, useState } from "react";
import { getAllDogsEntered, getAwardCards } from "../../api";
import { Link } from "react-router-dom";
import CertificateModal from "./CertificateModal";
export const DogEntries = () => {
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(false);
      const user = JSON.parse(localStorage.getItem("user"));
      const res = await getAllDogsEntered(user?.id);
      setData(res && res);
      setLoading(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    changeData();
  }, [data]);

  const changeData = () => {
    // var dd = [];
    // console.log(data);
    // var d = Object.keys(
    //   data?.reduce((r, { title }) => ((r[title] = ""), r), {})
    //   //   data?.reduce((r, { title, ev_id }) => ((r[title] = r[title]), r), {})
    // );
    // for (let a of d) {
    //   dd.push({ title: a, dogs: [] });
    // }
    // for (let i in dd) {
    //   for (let d_ of data) {
    //     if (d_?.title == d[i]) {
    //       dd[i]?.dogs?.push(d_);
    //       //   uniqueEvIds.push(d_.ev_id);
    //     }
    //   }
    // }
    let uniqueEvIds = [];
    data?.forEach((item) => uniqueEvIds.push(item.ev_id));
    uniqueEvIds = new Set(uniqueEvIds);
    uniqueEvIds = [...uniqueEvIds];
    const uniqueObjectOfArrays = {};
    uniqueEvIds.forEach((item) => {
      uniqueObjectOfArrays[item] = [];
    });
    data.forEach((item) => {
      uniqueObjectOfArrays[item.ev_id].push(item);
    });
    const finalArray = Object.values(uniqueObjectOfArrays);
    console.log("Final Array: ", finalArray);

    setEvents(finalArray && finalArray);
    // console.log(events);
  };

  const getDogCards = async (id) => {
    setOpenModal(id);
    setCards([]);

    const res = await getAwardCards(id);
      setCards(res && res.data);
  }

  return (
    <div className="dog-entries-main p-4">
      <h1>Dog Enteries</h1>
      {events && events.length
        ? events?.map((event, ind) => (
            <div className="dog-entry" key={ind}>
              <h5 className="p-2 bg-light rounded shadow-sm">
                {event[0]?.title+' ('+new Date(event[0]?.start_date).toDateString()+')'}
              </h5>
              <a href={`https://admin.gundogclub.pk/admin/shows/pdf/${event[0]?.friendly_URL || "#"}.pdf`}
                style={{
                  color: "white",
                }}
                className="btn btn-info shadow-sm"
              >
                View Catalog
              </a>
              <table className="  shadow-sm mt-2   p-2 w-100">
                <thead>
                    <tr className="bg-light p-2 mx-1 mt-2 rounded shadow-sm">
                        <th>
                            <span>Event Name</span>
                        </th>
                        <th>
                            <span>Dog Name</span>
                        </th>
                        <th>
                            <span>Class</span>
                        </th>
                        <th>
                            <span>Date of Event</span>
                        </th>
                        <th>
                            <span>Result</span>
                        </th>
                        <th>
                            <span>Certificates</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                {event.map((item, _i) => (
                  <tr className="p-2" key={_i}>
                    <CertificateModal
                      id={item.id}
                      open={openModal}
                      setOpen={setOpenModal}
                      data={cards}
                    />
                        <td>{item?.title}</td>
                        <td>{item?.dog_name} </td>
                        <td>{item?.class_name}</td>
                        <td>{new Date(item?.start_date)?.toDateString()}</td>
                        <td>{item?.result || "-"}</td>
                        <td>
                        {item?.result ? (
                            // <Link to="#" className="btn btn-sm  btn-info">
                            <button
                            onClick={() => getDogCards(item.id)}
                            className="btn btn-sm btn-info"
                            style={{
                                color: "white",
                            }}
                            >
                            Certificate
                            </button>
                        ) : (
                            // {/* </Link> */}
                            "-"
                        )}
                        </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          ))
        : null}
    </div>
  );
};

export default DogEntries;
