import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { getKennelDetailsById } from "../../../api";
import logo from '../../../assets/Images/website_logo_large-300x241.png';
import placeHolder from '../../../assets/Images/image_paceholder.png';
const KennelDetails = () => {
    const {id}=useParams()
    const [data,setData]=useState({})

    useEffect(async() => {
        const res=await getKennelDetailsById(id)
        setData(res&&res[0])

    }, [id])

  return (
    <div className="row mx-0 bg-white rounded p-2 ">
    {/* <div className="col-md-2  rounded">
      <div className="logo">
        <img className="" src={logo} />
      </div>
      <hr />
    </div> */}
    <div className="col-md-12 my-2 px-0 ">

    <div className=" row mx-4">
        <div className="col-md-4">
            <div className="kennel-img">
                <img className="rounded-circle shadow-sm" src={placeHolder}/>
            </div>
        </div>
        <div className="col-md-8 d-flex justify-content-center align-items-start">
        <h1 className="my-auto ">{data?.kennel_name}</h1>
        <hr/>
        </div>
    </div>
    
    <div className="row mx-4 px-5 my-4 bg-light shadow-sm">
        <div className="col-md-6 d-flex">
            <div className="p-2"><b>Suffix:</b></div>
            <div  className="p-2">{data?.suffix}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Phone No:</b></div>
            <div  className="p-2">{data?.phone}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Email:</b></div>
            <div  className="p-2">{data?.email}</div>
        </div>
   
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Address:</b></div>
            <div  className="p-2">{data?.social}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Social Media:</b></div>
            <div  className="p-2">{data?.suffix}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Website:</b></div>
            <div  className="p-2">{data?.websile}</div>
        </div>
   
    </div>
    </div>
    <div className="col-md-2  rounded justify-content-center align-items-center">
      {/* <img className="rounded mt-2 w-100" src={sideimg} /> */}
    </div>
  </div>
   
  );
};

export default KennelDetails;
