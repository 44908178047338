import React, { useContext } from 'react'
import {Link, useParams} from 'react-router-dom';
import { GlobalContext } from '../../context/store';

const Sidebar = ({id}) => {
    const {dogId}=useContext(GlobalContext)
    return (
        <div className="side-menu ">
        <ul className="mx-2 p-2 rounded">
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/bio/${id}`}> Basic Data </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/virtualbreeding/${id}`}> Virtual Breeding </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/siblings/${id}`}> Siblings </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/progeny/${id}`}> Progeny </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/pedigree/${id}`}> Pedigree </Link></li>
        </ul>
    </div>
    )
}

export default Sidebar
