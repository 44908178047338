import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/store";

const MobileSideNav = () => {
  const { isMobileMenu, setMobileMenu, user } = useContext(GlobalContext);
  const [isMenu, setIsMenu] = useState(false);

  return (
    <div
      className={`pb-2 side-nav-main ${
        isMobileMenu ? "sidenav-show" : "sidenav-hide"
      }`}
    >
      <div className="sidenav-header d-flex justify-content-between align-items-center">
        <h1>GDCP MENU</h1>
        <span
          onClick={() => {
            setMobileMenu(false);
          }}
          className="sidenav-closebtn"
        >
          <i class="fas fa-times"></i>
        </span>
      </div>
      <ul className="side-nav-items mt-2 mx-0 px-0 pb-5">
        <li className="side-nav-item">
          <Link
            onClick={() => {
              setIsMenu(!isMenu);
            }}
            to="#"
          >
            About
          </Link>
          <ul
            className={`sidenav-childmenu ${
              isMenu ? "sidenav-childmenu-show" : "sidenav-childmenu-hide"
            }`}
          >
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setIsMenu(false);
                  setMobileMenu(false);
                }}
                to="/about"
              >
                About The Club
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setIsMenu(false);
                  setMobileMenu(false);
                }}
                to="/about/subscription"
              >
                Subscription And Fee Structure
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setIsMenu(false);
                  setMobileMenu(false);
                }}
                to="/rules/code-of-ethics"
              >
                Rules And Regulations
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setIsMenu(false);
                  setMobileMenu(false);
                }}
                to="/about/gdcp-team"
              >
                The GDCP Team
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setIsMenu(false);
                  setMobileMenu(false);
                }}
                to="/about/gdcp-judges"
              >
                GDCP Judges
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setIsMenu(false);
                  setMobileMenu(false);
                }}
                to="/about/visiting-judges"
              >
                Visiting Judges
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setIsMenu(false);
                  setMobileMenu(false);
                }}
                to="/about/news-and-updates"
              >
                News and Updates
              </Link>
            </li>
          </ul>
        </li>
        <li className="side-nav-item">
          <Link
            onClick={() => {
              setMobileMenu(false);
            }}
            to="/breeds"
          >
            PEDIGREE DATABASE
          </Link>
        </li>
        {/* <li className="side-nav-item">
          <Link
            onClick={() => {
              setMobileMenu(false);
            }}
            to="/kennels-directory"
          >
            KENNELS DIRECTROY
          </Link>
        </li> */}
        <li className="side-nav-item">
          <Link
            onClick={() => {
              setMobileMenu(false);
            }}
            to="/members-directory"
          >
            MEMBERS DIRECTORY
          </Link>
        </li>
        <li className="side-nav-item">
          <Link
            onClick={() => {
              setMobileMenu(false);
            }}
            to="/events"
          >
            EVENTS
          </Link>
        </li>
        <li className="side-nav-item">
          <Link to="/show-results">SHOW RESULTS</Link>
        </li>
        {/* <li className="side-nav-item">
          <Link
            onClick={() => {
              setMobileMenu(false);
            }}
            to="/records-of-recent-matings"
          >
            Record of Recent Matings
          </Link>
        </li> */}
        {user ? (
          <>
          <hr/>
            {/* <li className="side-nav-item">
              <Link
                onClick={() => {
                  setMobileMenu(false);
                }}
                to={`/member/stud-certificates`}
              >
                {" "}
                Stud Certificate{" "}
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setMobileMenu(false);
                }}
                to={`/member/litter-inspection`}
              >
                {" "}
                Litter Inspection{" "}
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setMobileMenu(false);
                }}
                to={`/member/litter-registration`}
              >
                {" "}
                Litter Registration{" "}
              </Link>
            </li> */}
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setMobileMenu(false);
                }}
                to={`/member/ownership-transfer`}
              >
                {" "}
                Transfer/Lease{" "}
              </Link>
            </li>
            <li className="side-nav-item">
              {" "}
              <Link
                onClick={() => {
                  setMobileMenu(false);
                }}
                to={`/member/my-show-survey-entries`}
              >
                {" "}
                Show Entries{" "}
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setMobileMenu(false);
                }}
                to={`/member/my-dog-entries`}
              >
                {" "}
                Dogs Entered{" "}
              </Link>
            </li>
            <li className="side-nav-item">
              <Link
                onClick={() => {
                  setMobileMenu(false);
                }}
                to={`/member/member-account-statement`}
              >
                {" "}
                Balance Statment{" "}
              </Link>
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};
export default MobileSideNav;
