import React, { useRef, useState } from 'react'
import { convertHashKey, currentTimeStamp} from '../../utils';

const PaymentCheckout = () => {

    const [url_params,setParams]=useState()
    const iframeRef=useRef(null);

     const loadIframe = () => {

        const url='https://easypaystg.easypaisa.com.pk/tpg/?'
        const tempURLparams=`amount=10.0&orderRefNum=1221&paymentMethod=InitialRequest&postBackURL=https://www.google.com/&storeId=15950&timeStamp=${currentTimeStamp()}`
        console.log(tempURLparams)

        let encryptedHashRequest=convertHashKey('Z8FUQRKRZTKVU2Q2',tempURLparams)       
    
        if (iframeRef) {
        let params=`${url}storeId=15950&orderId=1221&transactionAmount=10.0&mobileAccountNo=&emailAddress=&transactionType=InitialRequest&tokenExpiry=&bankIdentificationNumber=&encryptedHashRequest=${encryptedHashRequest}&merchantPaymentMethod=&postBackURL=https%3A%2F%2Fwww.google.com%2F&signature=`
        setParams(params)
            return false;
            }
            return true;
        };

    return (
        <div>
            <button type="submit" name="pay" class="btn btn-primary" id="submitPaymentMethod"
                onClick={()=>{loadIframe()}}>Continue to Easypay Portal</button>

                <a href={url_params}>go link ahead</a>
                    <iframe 
                    className='show-iframe'
                    ref={iframeRef}
                    id="easypay-iframe" name="easypay-iframe" 
                    src={url_params} width="100%"
                    height="500px">
                    </iframe>
        </div>
    )
}

export default PaymentCheckout;
