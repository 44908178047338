import React from 'react'
import { Link } from 'react-router-dom'
// import Logo from '../../../assets/Images/Logo.png'
import Logo from '../../assets/Images/Logo.png'

const Header = () => {
  const user=localStorage.getItem('user')
    return (
        <nav className="navbar navbar-expand-lg navbar-main bg-header shadow" style={{minHeight:'50px'}}>
        <div className="container row">
          <div className="col-md-4 d-flex justify-content-between">
          <Link to="/" className="navbar-brand shadow" href="#" style={{zIndex:1000,backgroundColor:'#016356',borderBottomLeftRadius:'30%',borderBottomRightRadius:'30%',padding:20}}><img src={Logo} style={{maxHeight:'100px',height:'auto'}}/></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <div className="container-toggle">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                  </div>
          </button>
          </div>
          <div className="col-md-8">
          
          <div className="collapse navbar-collapse" id="navbarNav" style={{flexGrow:0}}>
            <ul className="navbar-nav">
          
              <li className="nav-item dropdowm">
              <Link className="nav-link" to="/about">THE CLUB</Link>
            <ul className="dropdown-menu-nav">
            <li><Link  to="/about">About GDCP</Link></li>
            <li><Link to="/about/subscription">Subscription And Fee Structure</Link></li>
            <li><Link to="/rules/code-of-ethics">Rules And Regulations</Link></li>
            <li><Link to="about/gdcp-team">The GDCP Team</Link></li>
            <li><Link to="about/gdcp-judges">GDCP Judges</Link></li>
            <li><Link to="about/visiting-judges">Visiting Judges</Link></li>
            <li><Link to="about/news-and-updates">News and Updates</Link></li>
          </ul>
        
        </li>
            <li className="nav-item">
                <Link className="nav-link" to="/breeds">PEDIGREE DATABASE</Link>
              </li>
            {/* <li className="nav-item">
                <Link className="nav-link" to="kennels-directory">KENNELS DIRECTORY</Link>
              </li> */}
            <li className="nav-item">
                <Link className="nav-link" to="members-directory">MEMBERS DIRECTORY</Link>
              </li>
            {/* <li className="nav-item">
                <Link className="nav-link" to="#">RECORD OF RECENT MATINGS</Link>
              </li> */}
            <li className="nav-item">
                <Link className="nav-link" to="events">EVENTS</Link>
              </li>
           
            <li className="nav-item">
                <Link className="nav-link" to="show-results">SHOW RESULTS</Link>
              </li> 
              {
                  user?.length > 0 ?
                  <li className="nav-item"><Link to="/member/my-profile" className="nav-link">My Profile</Link></li>
                  :
                  <li className="nav-item"><Link to="login" className="nav-link">Login</Link></li>
              }  
            </ul>
          </div>
          </div>
        </div>
      </nav>
    )
}

export default Header
