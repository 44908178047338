import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTeam } from "../../../api";
import placeHolder from "../../../assets/Images/userPlaceholder.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {motion} from 'framer-motion';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TheTeam = () => {
  const [team, setTeam] = useState([]);
  const [sortedTeam, setSortedTeam] = useState([]);
  const [loading,setLoading]=useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await getTeam();
      setTeam(res && res);
      setTimeout(()=>{setLoading(false)},100)
      
    };
    fetchData();
  }, []);

  useEffect(()=>{changeData()},[team])

  const changeData=()=>{
    var dd=[];
      var d=Object.keys(team&&team?.reduce((r,{committie_name}) => (r[committie_name]='', r) , {}))
      for(let a of d){
          dd.push({title:a,team:[]})
      }
    for(let i in dd){
        for(let d_ of team){
            if(d_?.committie_name==d[i]){
                dd[i]?.team?.push(d_)
            }
        }
    }
    setSortedTeam(dd&&dd)
}   
  const imgURL = "https://admin.gundogclub.pk/website_images/team-members/";
  
  const pageTransition={
    in:{
        opacity:1,
        x:0
    },
    out:{
        opacity:0,
        x:'-100vw'
    }
}

return (
    <motion.div 
    initial="out"
    animate="in"
    exit="out"
    variants={pageTransition}
    className="container">
        
      <h1>The GDCP Team</h1>
      <div className="container">
     
      {
        loading?
        <div className="mt-5">
          <Skeleton height={40} width={'50%'}/>
          <hr/>
          <div className="row">
            {
            [0,1,2,].map((a)=>(<div className="col-md-4  my-2">
              <div className="border mx-2 rounded">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-8">
              <Skeleton width={150} height={150} className="text-center shadow-sm rounded-circle"/>
                </div>
                <div className="my-2 col-md-10">
              <Skeleton width={'100%'} height={25} className="shadow-sm my-2"/>
              <Skeleton width={'90%'} height={25} className="shadow shadow-sm my-1"/>
              <Skeleton width={'90%'} height={15} className="shadow shadow-sm my-1"/>
              </div>
              </div>
           
              </div>
         
            </div>))
            }
          </div>
        </div>
        :
        sortedTeam?sortedTeam?.map(({title,team})=>(
          <div className="mt-2">
          <h4 className="fw-bold txt-primary mt-5">{title}</h4>
          <hr/>
          {
              <div className="row">
              {team && team.length
                ? team?.map(
                    ({
                      full_name,
                      position_name,
                      description,
                      image,
                      url_link,
                      facebook_url,
                      youtube_url,
                      twitter_url,
                    }) => (
                      <div class="col-md-4 container mt-4 mb-4 p-3 d-flex justify-content-start">
                        <div class="p_card p-4 shadow bg-white">
                          <div class=" image d-flex flex-column justify-content-center align-items-center">
                            {
                            image?
                            <LazyLoadImage
                            alt={full_name}
                            effect="blur"
                            Height={'200px'}
                            width={'200px'}
                            aiamgelt="Card image cap"
                            className="card-img-top p-2 rounded-circle shadow mb-2"
                           
                            src={imgURL +image}
                          />
                            :
                            <img
                              className="shadow rounded-circle shadow mb-2"
                              src={placeHolder}
                              height="150"
                              width="158"
                            />}
                            <span class="name text-center mt-3 text-center">{full_name}</span>{" "}
                            <span class="idd">{position_name}</span>
                            {description ? (
                              <div class=" d-flex mt-2">
                                {" "}
                                <Link
                                  to={url_link || "#"}
                                  className="btn  butn-grey shadow-sm"
                                >
                                  Read Bio
                                </Link>{" "}
                              </div>
                            ) : null}
                            <div class="gap-3 mt-3 icons d-flex flex-row justify-content-center align-items-center">
                              {" "}{
                                facebook_url?
                                <a href={facebook_url?facebook_url:"#"}>
                                <i class="fab fa-facebook"></i>
                              </a>
                              :null
                              }
                              {
                                youtube_url?
                                <a href={youtube_url?youtube_url:"#"}>
                                <i class="fab fa-youtube"></i>
                              </a>:null
                              }
                            {" "}
                            {
                              twitter_url?
                              <a href={twitter_url?twitter_url:"#"}>
                              <i class="fab fa-twitter"></i>
                            </a>:null
                            }
                             {" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )
                : null}
            </div>
          }
        </div>
        )):null
      }
    
    </div>
    </motion.div>
  );
};

export default TheTeam;
