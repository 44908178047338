import React, {useState, useEffect} from 'react'
import { Outlet } from 'react-router'
import Sidebar_Rules from '../../../layouts/SideNavigation_Rules'
import {motion} from 'framer-motion';
import { getAllRules } from '../../../api';
import {Link, useParams} from 'react-router-dom';
const RulesAndRegulations = () => {
    const pageTransition={
        in:{
            opacity:1,
            x:0
        },
        out:{
            opacity:0,
            x:'-100vw'
        }
    }

    const[rules,setRules]=useState([])

    const toggleMenu = (e, type) => {
        const menu = document.getElementById(type);
        // const arrow = document.getElementById('menu-arrow');
        const isExpanded = menu.classList.toggle('show');
        const isExpanded2 = menu.classList.toggle('collapse');
        // arrow.classList.toggle('expanded', isExpanded);
        // document.getElementById('menu-link').setAttribute('aria-expanded', isExpanded);
    }

    useEffect(()=>{
        const fetchData=async()=>{
            const data=await getAllRules()
            setRules(data&&data)
        }

        fetchData()

    },[])

    return (
        <motion.div 
        initial="out"
        animate="in"
        exit="out"
        variants={pageTransition}
         className="mt-5 container">
            <div>
                <h1 style={{float: 'left'}}>Rules And Regulations</h1>
                <a id="rules-menu-icon" aria-expanded="false" style={{float: 'right'}} class="nav-tabs-link" href="#" onClick={(e) => toggleMenu(e, 'rules-menu')}>
                    <i class="fas fa-bars"></i>
                </a>
                <ul id="rules-menu" className="mx-2 p-2 rounded collapse" aria-expanded="false" style={{clear: 'both'}}>
                    {
                        rules.length > 0 ? rules?.map(({rule_name,friendly_url})=>(

                            <li onClick={(e) => toggleMenu(e, 'rules-menu')}><i className="fas fa-chevron-right"></i> &nbsp; <Link to={friendly_url?friendly_url:"#"}> {rule_name}</Link></li>
                        ))
                        :
                        <></>
                    }
                </ul>
            </div>
            <div className="row" style={{clear: 'both'}}>
                <div className="col-md-3">
                    <Sidebar_Rules/>
                </div>
                <div className="col-md-9">
                    <Outlet/>
                </div>
            </div>

        </motion.div>
    )
}

export default RulesAndRegulations
