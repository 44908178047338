import React from 'react'
import { Link } from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import placeHolder from '../assets/Images/dummy-image.jpg'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


const BlogPost =  ({title,description,link}) => {
    return (
      <div  className="col-md-4 blog-post-main ">
        <div className="my-2 blurred-box"  style={{minHeight:'300px'}} >
        {/* <img className="card-img-top" src="https://thegundogclub.co.uk/wp-content/uploads/2021/01/unnamed-350x263.jpg" alt="Card image cap"/> */}
        <div className="card-body">
          {/* <img src={placeHolder} style={{maxWidth:'350px',width:'100%'}}/> */}
          <h4 className="card-title mt-3 fw-bold" >{title?.split("").splice(0,35)}...</h4>
          <p className="text-dark fw-normal">
         {description?.split("").splice(0,60)}...
          </p>
        </div>
        <div className='d-flex justify-content-center aling-items-center my-3'>
          <Link to={"/blog/"+link||'#'} className="btn btn-primary rounded">Read More</Link>
        </div>
      </div>
        </div>
    )
}

export default BlogPost;
