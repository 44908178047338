import React, { useContext, useEffect, useRef, useState } from "react";
import { getAllCities, getUserById, updateUserProfile } from "../api";
import Select from "react-select";
import { toast } from "react-toastify";
import easypaisa from '../assets/Images/easypaisa.png'
import ubl from '../assets/Images/ubl.png'
import axios from "axios";
import { GlobalContext } from "../context/store";

const TopUpModal = () => {

  const [method,setMoethod]=useState(null)
  const [amount,setAmount]=useState(0)
  const [ublTransId,setUblTrans]=useState("")
  const [loading,setLoading]=useState(false)
  const { user } = useContext(GlobalContext);


  const hideModal=()=>{
    let el = document.getElementById('closeModal');
    if (el.onclick) {
       el.onclick();
    } else if (el.click) {
       el.click();
    }
  }

  useEffect(()=>{
    ublTopUp()
  },[amount])

  const ublTopUp=async()=>{
    setLoading(true)
    const res= await axios.get(`https://admin.gundogclub.pk/api/top-up-id?amounts=${amount}&user_id=${user?.id}`)
    if(res.status===200){
      setUblTrans(res.data.Transaction.TransactionID)
      setLoading(false)
    }
  }

  return (
    <div
      class="modal fade"
      id="topupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="topupModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-bold" id="topupModalLabel">
             Topup Amount
            </h5>
            <button
              type="button"
              class="close btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form action="https://ipg.comtrust.ae/PaymentEx/MerchantPay/Payment?lang=en&layout=C0STCBVLEI" method="POST">
          <div class="modal-body ">

          <div className="form-group  mx-2">
            <input
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter Amount"
              type="text"
              className="form-control"
              required
            />
          </div>
          <div className="form-group my-3 mx-2">
          <lable className="fw-bold">Select Payment Method</lable>
          </div>
          <div className="p-2 w-75">

            <input required type="radio" id="shipadd1" onChange={(e)=>{setMoethod('UBL')}} value="UBL" name="address" />
            <label for="shipadd1"></label>

            <input required type="radio" disabled id="shipadd2" onChange={(e)=>{setMoethod('EASYPAISA')}} value="EASYPAISA" name="address" />
            <label for="shipadd2"></label>

          </div>
          </div>
          <div class="modal-footer">
            {
              !loading?
              <>
                    <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              id="closeModal"
              >
              Cancel
            </button>
          
                   <input type="hidden" name="TransactionID" id="TransactionID" value={ublTransId}/>
                   <input type="hidden" required  value={method}/>
                   {
                     method?
                     <button type="submit" class="btn btn-primary" disabled={!method?true:false}>Yes, proceed</button>
                      :null
                   }
              </>:<span class="fw-bold text-primary">Please wait ....</span>
            }
      
     
            {/* <button type="button" class="btn btn-primary" onClick={()=>topUp()}>
              Proceed
            </button> */}
          </div>
            </form>
        </div>
      </div>
    </div>
  );
};

export default TopUpModal;
