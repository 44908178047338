import React, { useContext } from 'react'
import {Link, useParams} from 'react-router-dom';
import { GlobalContext } from '../../context/store';

const Sidebar_Member = ({id}) => {
    const {dogId}=useContext(GlobalContext)
    return (
        <div className="side-menu ">
        <ul className="mx-2 p-2 rounded">
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/my-profile`}> My Profile </Link></li>
            {/* <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/stud-certificates`}> Stud Certificate </Link></li> */}
            {/* <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/litter-inspection`}> Litter Inspection </Link></li> */}
            {/* <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/litter-registration`}> Litter Registration </Link></li> */}
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/ownership-transfer`}> Transfer/Lease </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/my-show-survey-entries`}> Show Entries </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/my-dog-entries`}> Dogs Entered </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/profile-news-updates`}> News & Updates </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/member-account-statement`}> Balance Statment </Link></li>
        </ul>
    </div>
    )
}

export default Sidebar_Member
