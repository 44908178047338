import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getAllFooterImages,
  getAllFooterLinks,
  getAllFooterSettings,
  getAD
} from "../../api";

const Footer = () => {
  const [Links, setLinks] = useState([]);
  const [Images, setImages] = useState([]);
  const [settings, setSettings] = useState([]);

  const [adverts, setAD] = useState(null);

  const AdData = async () => {
    const res = await getAD();
    setAD(res && res);
    console.log(res);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllFooterLinks();
      setLinks(res && res);
      const res_ = await getAllFooterImages();
      setImages(res_ && res_);
      const fs = await getAllFooterSettings();
      setSettings(fs && fs);
    };
    fetchData();

    AdData();
  }, []);

  function convertHTMLEntity(text){
    const span = document.createElement('span');

    return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
        span.innerHTML = entity;
        return span.innerText;
    });
}
  const ImageURL="https://admin.gundogclub.pk/website_images/footer_images/"

  return (
      <>
        <div className="footer-main">
          <div className="row mx-auto p-5">
            <div className="col-md-3">
              <div className="footer-logo d-flex justify-content-center align-items-center" >
                {Images &&
                  Images?.map(({ image, position }, ind) =>
                    position === "Footer Main Image" ? (
                      <img style={{maxWidth:'130px'}} key={ind} src={ImageURL+image} alt={image} />
                    ) : null
                  )}
              </div>
              <h5 className="footer-heading">Site links:</h5>
              <ul className="mx-0  px-0 footer-links mb-0">
                {Links
                  ? Links?.map((item, ind) => (
                      <li key={ind} className="p-2">
                        <Link to={`/${item?.link || "#"}`}>{item?.link_name}</Link>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
            <div className="col-md-6">
              <div className="footer-images d-flex justify-content-around flex-wrap align-items-center">
                {Images &&
                  Images?.map(({ image, position }, ind) =>
                    position === "Latest Project" ? (
                      <img 
                      style={{maxWidth:'100px'}}
                      key={ind} src={ImageURL+image} alt={image} />
                    ) : null
                  )}
              </div>
              <div className="footer-description py-5 ">
              {settings
              ?.filter(({ setting_name }) => setting_name == "footer_description")
              ?.map((address) => (
                <p  className="text-white" dangerouslySetInnerHTML={{__html: convertHTMLEntity(address?.setting_value)}}></p>
              ))}
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-address">
                <h5 className="footer-heading">Address:</h5>
                {settings
                  ?.filter(({ setting_name }) => setting_name == "office_address")
                  ?.map((address) => (
                    <span>
                      <i class="fas fa-map-marked-alt mx-2"></i>
                      {address?.setting_value}
                    </span>
                  ))}
              </div>
              <div className="footer-contact mt-4">
                <h5 className="footer-heading">Contact:</h5>
                <ul className="footer-contact-list">
                  {settings
                    ?.filter(({ setting_name }) => setting_name == "contact_number")
                    ?.map((address) => (
                      <li>
                        <i class="fas fa-phone mx-2"></i>
                        {address?.setting_value}
                      </li>
                    ))}
                  {settings
                    ?.filter(({ setting_name }) => setting_name == "contact_email")
                    ?.map((address) => (
                      <li>
                        <i class="fas fa-envelope mx-2"></i>
                        {address?.setting_value}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="footer-social mt-4">
                <div className="footer-social-icons  d-flex">
                  {settings
                    ?.filter(
                      ({ setting_name }) =>
                        setting_name == "facebook_url"
                    )
                    ?.map(({ setting_value }) => (
                      <span>
                        <a href={setting_value || "#"} target="_blank">
                          <i class="shadow fab fa-facebook mx-2"></i>
                        </a>
                      </span>
                    ))}
                  {settings
                    ?.filter(
                      ({ setting_name, setting_value }) =>
                        setting_name == "youtube_url"
                    )
                    ?.map(({ setting_value }) => (
                      <span>
                        <a href={setting_value || "#"} target="_blank">
                          <i class="shadow fab fa-youtube"></i>
                        </a>
                      </span>
                    ))}
                  {settings
                    ?.filter(({ setting_name }) => setting_name == "twitter_url")
                    ?.map(({ setting_value }) => (
                      <span>
                        <a href={setting_value || "#"} target="_blank">
                          <i class="shadow fab fa-twitter mx-2"></i>
                        </a>
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
      
      
          <div className="footer-main-copyright p-4 text-light">
            {settings
              ?.filter(({ setting_name }) => setting_name == "copyright")
              ?.map((address) => (
                <div dangerouslySetInnerHTML={{__html: convertHTMLEntity(address?.setting_value)}}></div>
                // <span>{address?.setting_value}</span>
              ))}
          </div>
        </div>
              
        {adverts?.ad && (
          <>
          <div class="gap"></div>
          <div class="row">
            <div class="footer_ad" style={{position: 'fixed',left: 0,bottom: 0,width: '100%',backgroundColor: 'transparent',color: 'white',textAlign: '-webkit-center',zIndex: 9999}}>
                {adverts?.ad?.link != null ?
                  <a href={adverts?.ad?.link}><img src={`https://admin.gundogclub.pk/website_images/footer_images/${adverts?.ad?.image}`} /></a>
                :
                  <a><img src={`https://admin.gundogclub.pk/website_images/footer_images/${adverts?.ad?.image}`} /></a>
                }
            </div>
          </div>
          </>
        )}
      </>
  );
};

export default Footer;
