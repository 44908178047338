import React, { useState, useEffect, useContext, Fragment } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getAllDogOwners, getDogById, getDogIdBySlug } from "../../../../api";
import placeHolder from "../../../../assets/Images/placeholderdog.jpg";
import { DOG_BREED } from "../../../../constants";
import { GlobalContext } from "../../../../context/store";
import logo from "../../../../assets/Images/Logo.png";
import Sidebar from "../../../../layouts/SideNavigation";
import Loader from "../../../../components/loader";
import EditDogImage from "../../../../Modals/EditDogImage";

import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const BasicData = () => {
  const { slug } = useParams();
  const [dog, setDog] = useState({});
  const [sire, setSire] = useState("");
  const [dam, setDam] = useState("");
  const [loading, setLoading] = useState(false);
  const [dog_owners, setDogOwners] = useState([]);
  const [show, setShow] = useState(false);
  const { user } = useContext(GlobalContext);
  const { setDogId } = useContext(GlobalContext);

  useEffect(async () => {
    setLoading(true);
    const id = await getDogIdBySlug(slug);
    const res = await getDogById(id && id[0]?.id);
    setDog(res && res[0]);
    const resـ = await getAllDogOwners(slug);
    setDogOwners(resـ);
    setLoading(false);
  }, [slug]);

  useEffect(async () => {
    setDogId(dog?.id);
    setShow(false);

    if (dog?.owners) {
      for (let d of dog?.owners?.split(",")) {
        if (d == user?.id) {
          setShow(true);
        } else {
          setShow(false);
        }
      }
    }
  }, [dog]);

  const pageTransition = {
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: "-100vw",
    },
  };

  const imageURL = `https://admin.gundogclub.pk/public/dog_images/${dog?.id}/`;
  return (
    <Fragment>
      {/* <div>
        <Helmet>
          <meta property="og:site_name" content="GDCP" />
          <meta property="og:title" content={dog?.dog_name} />
          <meta
            property="og:image"
            content={dog?.image ? imageURL + dog?.image : logo}
          />
          <meta property="og:url" content="https://www.gundogclub.pk/" />
        </Helmet>
      </div> */}
      <motion.div
        variants={pageTransition}
        initial="out"
        animate="in"
        exit="out"
        className="row mx-0 mt-5 bg-white rounded p-2 "
      >
        <div className="col-md-2  rounded">
          <Sidebar id={dog?.friendly_URL} />
        </div>
        <div className="col-md-10 my-2 px-0 ">
          <div className="col-md-12  mt-3 py-4 rounded shadow px-3">
            <div className="col-md-12  ">
              <h3 className="my-3 bg-light p-2 rounded shadow-sm w-100">
                {dog && dog?.dog_name}
                <span className="breed-kp mx-2">{dog && dog?.KP}</span>{" "}
                <i
                  className={`fas ${
                    dog?.sex == "Male" ? "fa-mars" : "fa-venus"
                  }`}
                ></i>
              </h3>
            </div>
            {loading ? (
              <Loader size={50} />
            ) : (
              <>
                <div className="row py-2 mx-0  bg-light basic_data-item  rounded shadow-sm ">
                  <h3 className="font-weight-bold">General</h3>
                  <div className="col-md-4">
                    <table className="reponsive-table ">
                      <tr>
                        <th>Registration:</th>
                        <td>{(dog && dog?.KP) || " --"}</td>
                      </tr>
                      <tr>
                        <th>Breed:</th>
                        <td>{DOG_BREED}</td>
                      </tr>
                      <tr>
                        <th>Coat:</th>
                        <td>{(dog && dog?.coat) || " --"}</td>
                      </tr>
                      <tr>
                        <th>Color:</th>
                        <td>{(dog && dog?.color) || " --"}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <table className="reponsive-table ">
                      <tr>
                        <th>Sex:</th>
                        <td>{(dog && dog?.sex) || " --"}</td>
                      </tr>
                      <tr>
                        <th>Microchip:</th>
                        <td>{(dog && dog?.microchip) || " --"}</td>
                      </tr>
                      <tr>
                        <th>Whelped:</th>
                        <td>{(dog && dog?.dob?.slice(0, 10)) || " --"}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-4 position-relative">
                    <div className=" dasic_data_dog_img rounded ">
                      <img
                        className="rounded shadow border"
                        src={dog?.image ? imageURL + dog?.image : placeHolder}
                      />

                      {show ? (
                        <button
                          data-toggle="modal"
                          data-target="#exampleModal"
                          className="rounded-circle btn-light px-2 py-1 position-absolute border"
                        >
                          <i class="fas text-dark fa-edit"></i>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 mt-1">
                    <table>
                      <tr>
                        <th>Line Breeding</th>
                        <td>No common ancestry was found in 5 generations</td>
                      </tr>
                    </table>
                  </div>

                  <div className="col-md-6">
                    <table className="table">
                      <tr></tr>
                    </table>
                  </div>
                </div>

                <div className="row py-2 mt-3 mx-0 bg-light basic_data-item basic_data-item  rounded shadow-sm ">
                  <h3 className="font-weight-bold">Parents</h3>
                  <div className="col-md-6">
                    <table className="reponsive-table ">
                      <tr>
                        <th>Sire:</th>
                        <td>
                          {dog && dog?.sire_id ? (
                            <Link to={`/dog/bio/${dog && dog?.sire_url}`}>
                              {dog && dog?.sire_name}
                            </Link>
                          ) : (
                            " --"
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="reponsive-table ">
                      <tr>
                        <th>Dam:</th>
                        <td>
                          {dog && dog?.sire_id ? (
                            <Link to={`/dog/bio/${dog && dog?.dam_url}`}>
                              {dog && dog?.dam_name}
                            </Link>
                          ) : (
                            " --"
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="table">
                      <tr></tr>
                    </table>
                  </div>
                </div>

                <div className="row py-2 mt-3 mx-0 bg-light basic_data-item basic_data-item  rounded shadow-sm ">
                  <h3 className="font-weight-bold">Ratings</h3>
                  <div className="col-md-6">
                    <table className="reponsive-table ">
                      <tr>
                        <th>Working Title:</th>
                        <td>{(dog && dog?.achievements) || "--"}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="reponsive-table ">
                      <tr>
                        <th>Show Title:</th>
                        <td>{(dog && dog?.show_title) || "--"}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="table">
                      <tr></tr>
                    </table>
                  </div>
                </div>

                <div className="row py-2 mt-3 mx-0 bg-light basic_data-item basic_data-item  rounded shadow-sm ">
                  <h3 className="font-weight-bold">Examinations</h3>
                  <div className="col-md-6">
                    <table className="reponsive-table ">
                      <tr>
                        <th>HD:</th>
                        <td>{dog && dog?.hip}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="reponsive-table ">
                      <tr>
                        <th>ED:</th>
                        <td>{dog && dog?.elbows}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="table">
                      <tr></tr>
                    </table>
                  </div>
                </div>

                <div className="row py-2 bg-light mt-3 mx-0 basic_data-item basic_data-item  rounded shadow-sm ">
                  <div className="col-md-6">
                    <h3 className="font-weight-bold">Breeder(s)</h3>
                    <table className="reponsive-table ">
                      <tr>
                        <td>
                          <td>{dog && dog?.breeder}</td>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <h3 className="font-weight-bold">Owner(s)</h3>
                    <table className="reponsive-table ">
                      <tr>
                        {dog_owners
                          ? dog_owners?.map(({ username, friendly_url }) => (
                              <Link
                                to={"/member-profile/" + friendly_url || "#"}
                                className="mx-2"
                              >
                                {username}
                              </Link>
                            ))
                          : "-"}
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="table">
                      <tr></tr>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-2  rounded justify-content-center align-items-center">
          {/* <img className="rounded mt-2 w-100" src={sideimg} /> */}
        </div>
        <EditDogImage url={slug} />
      </motion.div>
    </Fragment>
  );
};

export default BasicData;
