import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { LinearProgress } from "@mui/material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const CertificateModal = ({ id, open, setOpen, data }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open === +id}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Award Certificate(s)
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Click to view an Award Certificate
        </Typography>
        <div className="modal-buttons">
          {data?.certificates?.title?.length > 0 ? (
            data?.certificates?.title?.map((card, _i) => (
              <a href={data?.certificates?.links[_i]}>
                <Button variant="contained">{card}</Button>
              </a>
            ))
          ) : (
            <LinearProgress
              sx={{
                width: "100%",
              }}
              color="secondary"
            />
          )}
          {/* <Button variant="contained">Show Result</Button> */}
        </div>
      </Box>
    </Modal>
  );
};

export default CertificateModal;
