import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getJudgesBio, getTeamBioBySlug } from '../../../../api';
import placeHolder from'../../../../assets/Images/userPlaceholder.png'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {motion} from 'framer-motion'
const JudgesBio = () => {

    const {slug}=useParams();
    const[bio,setBio]=useState({})

    useEffect(()=>{

        const fetchData=async()=>{
            const res=await getJudgesBio(slug)
            setBio(res&&res[0])
        }
        fetchData()

    },[slug])

    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }
    const imgURL="https://admin.gundogclub.pk/public/judge_images/";

    const pageTransition={
        in:{
            opacity:1,
            x:0
        },
        out:{
            opacity:0,
            x:'-100vw'
        }
    }
    return (
        <motion.div
        variants={pageTransition}
        animate="in"
        initial="out"
        exit="out"
        className="container"
        >
        <div id="circle-shape-example">
            <div className='curve p-5'>
                
            {bio?.image ? (
                        <LazyLoadImage
                          alt={bio?.full_name}
                          effect="blur"
                          aiamgelt="Card image cap"
                          className="card-img-top p-2 rounded-circle shadow mb-2"
                          Height={'200px'}
                          width={'200px'}
                          src={imgURL + bio?.image}
                        />
                      ) : (
                        <img
                          className="card-img-top rounded-circle"
                          src={placeHolder}
                          alt={bio?.full_name}
                          aiamgelt="Card image cap"
                          style={{ maxHeight: 200, width: "auto" }}
                        />
                      )}

                {/* <img src={bio?.image?imgURL+bio?.image:placeHolder} alt={bio?.full_name} class="curve p-5"/> */}
            </div>
        <div><h1>{bio?.full_name}</h1></div>
  <div dangerouslySetInnerHTML={{__html: convertHTMLEntity(bio?.description)}}></div>
</div>
        </motion.div>
    )
}

export default JudgesBio
