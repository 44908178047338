import React, { useEffect, useState } from 'react'
import { getAllBlogs } from '../../api'
import News from '../../components/news'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {motion} from 'framer-motion'

const NewsAndUpdates = () => {
    const [posts,setPosts]=useState([])
    const [loading,setLoading]=useState(false)

    useEffect(()=>{
        const fetchData=async()=>{
            setLoading(true)
            const res=await getAllBlogs()
            setPosts(res&&res)
            setTimeout(()=>{setLoading(false)},1000)   
        }
        fetchData()
    },[])

    const pageTransition={
        in:{
            opacity:1,
            x:0
        },
        out:{
            opacity:0,
            x:'-100vw'
        }
    }

    return (
        <motion.div 
        initial="out"
        animate="in"
        exit="out"
        variants={pageTransition}>
            <h2>Gundog Club of Pakistan GDCP</h2>
            <h4 className="text-primary fw-bolder">News Updates</h4>
            <hr/>
            {
                loading?
                [0,1,2,3,4,5,6].map((_,ind)=>
                (
                    <div className='row border rounded mb-3 shadow-sm p-2' key={ind}>
                        <Skeleton height={35} width={'40%'} className='mb-2'/>
                        <Skeleton height={18} width={'25%'}/>
                        <Skeleton height={18} width={'95%'}/>
                        <Skeleton height={18}  width={'65%'}/>
                        <Skeleton height={40} className='btn my-3'  width={'10%'}/>
                        {/* <hr className='mt-2'/> */}
                    </div>
                ))
                :
                posts&&posts?.map(({preview_text,title,link,publishing_date})=>(
                    <News link={link} title={title} content={preview_text} posting_date={new Date(publishing_date).toDateString()}/>
                ))
            }
       </motion.div>
    )
}

export default NewsAndUpdates
