import React from 'react'
import { Link } from 'react-router-dom'
import { baseURL } from '../constants'
import placeHolder from '../assets/Images/placeholder.jpg'

const Breed =  ({title,image,link}) => {

    const imgURL=baseURL+"/storage/app/public/breed_images/"
    return (
        <div className="card shadow-sm p-4 mx-2 d-flex flex-column justify-content-between align-items-center" style={{minHeight:'380px'}} >
          <div className="d-flex justify-content-center align-items-center">
          <img className="card-img-top" src={image?imgURL+image:placeHolder}
        alt={title} style={{maxHeight:'200px',maxWidth:'220px'}}/>
          </div>

        <div className="card-body d-flex flex-column justify-content-end">
          <h4 className="card-title">{title}</h4>
          {/* <p className="text-dark">
         {description?.split("").splice(0,60)}...
          </p> */}
          <div className='d-flex justify-content-between aling-items-center'>
          <Link className="butn butn-grey"  to={`/gdcp-database/${link}`||"#"}>View Dogs</Link>
          <Link to={"/breeds/"+link||'#'} className="butn butn-grey">Read More</Link>
          </div>
    
        </div>
      </div>
    
    )
}
export default Breed;
