import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getAlldogs, getDogById, getDogIdBySlug, getPadigree } from "../../../../api";
import placeHolder from "../../../../assets/Images/placeholderdog.jpg";

import PadigreeDetails from "../../../../components/padigree_details";
import logo from '../../../../assets/Images/website_logo_large-300x241.png';
import Sidebar from "../../../../layouts/SideNavigation";
import { Link } from "react-router-dom";
import {motion} from 'framer-motion';

const Padigree = () => {
  const [padigree, setPadigree] = useState([]);
  const [dog, setDog] = useState({});

  const {slug}=useParams()

  useEffect(async () => {
    const id=await getDogIdBySlug(slug)
    const dog_id = await getDogById(id&&id[0]?.id);
    setDog(dog_id&&dog_id[0]);
    const res = await getPadigree(id&&id[0]?.id);
    setPadigree(res&&res[0]);
  }, [slug]);

  const pageTransition={
    in:{
        opacity:1,
        x:0
    },
    out:{
        opacity:0,
        x:'-100vw'
    }
}
  return (
    <motion.div
     variants={pageTransition}
      animate="in"
      initial="out"
      exit="out"
      className="row mx-0 mt-5 bg-white rounded p-2 ">

    <div className="col-md-2 rounded">
      <Sidebar id={slug}/>
    </div>
    <div className="col-md-10 my-2 px-0 ">
    <div>
      <h3 className="my-3 bg-light p-2 rounded shadow-sm w-100 ">
        {dog && dog?.dog_name}
        {dog && dog.KP ? (
          <span className="breed-kp px-2"> ( KP {dog?.KP} ) </span>
        ) : null}
        <i className={`fas ${dog?.sex == "Male" ? "fa-mars" : "fa-venus"}`}></i>
      </h3>
      <Link className="butn butn-grey shadow border rounded" to={`/dog/full-pedigree/${dog?.friendly_URL}`}>
        Full Pedigree
        </Link>
              <div id="tree">
        <div className="branch">
          <div className="entry">
            <span>
              <div
                id={padigree&&padigree?.father_id }
              >
                <div
                  className="paddigery-img-ii"
                >
                  <img
                    src={
                      padigree?.father_image
                        ? padigree?.father_image
                        : placeHolder
                    }
                  />
                </div>
                <div className="paddigery-text py-2">
                  <h6 className="font-weight-bold">
                 {padigree?.father?padigree?.father?.slice(0, 20) + "...." : "Unkown"}
                  </h6>
                </div>
              </div>
                    <PadigreeDetails dog_id={padigree?.father_id}/>
            </span>
            <div className="branch">
              <div className="entry">
                <span>
                  <div
                  
                  style={{ zIndex: 1000 }}
                  id={padigree&& padigree?.father_id }
                  >
                  <div    
                   className="d-flex justify-content-between align-items-center">
                    <div className="paddigery-img-i">
                      <img
                        src={
                          padigree && padigree?.fatherFather_image
                            ? padigree?.fatherFather_image
                            : placeHolder
                        }
                      />
                    </div>
                    <div className="paddigery-text py-2">
                      <h6 className="font-weight-bold">
                        {padigree &&
                         padigree?.fatherFather? padigree?.fatherFather?.slice(0, 13) + "....":
                          "Unkown"}
                      </h6>
                    </div>
                  </div>
                  </div>
                  <PadigreeDetails dog_id={ padigree?.fatherFather_id}/>
                </span>
                <div className="branch">
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-iii">
                          <img
                            src={
                              padigree && padigree?.fathersfathersfather_image
                                ? padigree?.fathersfathersfather_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree &&
                              padigree?.fathersfathersfather?padigree?.fathersfathersfather?.slice(0, 13) +
                                "....":
                              "Unkown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails dog_id={padigree?.fathersfathersfather_id}/>
                    </span>
                  </div>
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-iii">
                          <img
                            src={
                              padigree&&
                                padigree?.fathersfathersmother_image
                                || placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.fathersfathersmother?padigree?.fathersfathersmother?.slice(0, 13) +
                              "...." : "Unknown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails dog_id={padigree?.fathersfathersmother_id}/>
                    </span>
                  </div>
                </div>
              </div>
              <div className="entry">
                <span>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="paddigery-img-i">
                      <img
                        src={
                          padigree&&
                             padigree?.fatherMother_image
                            || placeHolder
                        }
                      />
                    </div>
                    <div className="paddigery-text py-2">
                      <h6 className="font-weight-bold">
                        {padigree?.fatherMother?padigree?.fatherMother?.slice(0, 13) + "...." :
                          "Unknown"}
                      </h6>
                    </div>
                  </div>
                  <PadigreeDetails dog_id={padigree?.fatherMother_id}/>
                </span>
                <div className="branch">
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-iii">
                          <img
                            src={
                              padigree&&
                                 padigree?.fathersmothersfather_image
                                || placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.fathersmothersfather?padigree?.fathersmothersfather?.slice(0, 13) +
                              "...." : "Unknown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails dog_id={padigree?.fathersmothersfather_id}/>
                    </span>
                  </div>
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-iii">
                          <img
                            src={
                              padigree&&
                                 padigree?.fathersmothersmother_image
                                || placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.fathersmothersmother?padigree?.fathersmothersmother?.slice(0, 13) +
                              "...." : "Unknown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails dog_id={padigree?.fathersmothersmother_id}/>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="entry">
            <span>
              <div>
                <div className="paddigery-img-ii">
                  <img
                    src={
                      padigree&&
                        padigree?.mother_image
                        || placeHolder
                    }
                  />
                </div>
                <div className="paddigery-text py-2">
                  <h6 className="font-weight-bold">
                    {padigree?.mother?padigree?.mother?.slice(0, 20) + "...." : "Unkown"}
                  </h6>
                </div>
              </div>
              <PadigreeDetails dog_id={padigree?.mother_id}/>
            </span>
            <div className="branch">
              <div className="entry">
                <span>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="paddigery-img-i">
                      <img
                        src={
                          padigree&&
                            padigree?.MotherFather_image
                        ||placeHolder
                        }
                      />
                    </div>
                    <div className="paddigery-text py-2">
                      <h6 className="font-weight-bold">
                        {padigree?.MotherFather?padigree?.MotherFather?.slice(0, 13) + "...." :
                          "Unknown"}
                      </h6>
                    </div>
                  </div>
                  <PadigreeDetails dog_id={padigree?.MotherFather_id}/>
                </span>
                <div className="branch">
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-iii">
                          <img
                            src={
                              padigree&&
                                padigree?.mothersfathersfather_image
                                || placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.mothersfathersfather?padigree?.mothersfathersfather?.slice(0, 13) +
                              "...." : "Unknown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails dog_id={padigree?.mothersfathersfather_id}/>
                    </span>
                  </div>
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-iii">
                          <img
                            src={
                              padigree&&
                                 padigree?.mothersfathersmother_image
                                || placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.mothersfathersmother?padigree?.mothersfathersmother?.slice(0, 13) +
                              "...." :"Unkown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails dog_id={padigree?.mothersfathersmother_id}/>
                    </span>
                  </div>
                </div>
              </div>
              <div className="entry">
                <span>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="paddigery-img-i">
                      <img
                        src={
                          padigree&&
                            padigree?.motherMother_image
                            ||placeHolder
                        }
                      />
                    </div>
                    <div className="paddigery-text py-2">
                      <h6 className="font-weight-bold">
                        {padigree?.motherMother?padigree?.motherMother?.slice(0, 13) + "...." :
                          "Unkown"}
                      </h6>
                    </div>
                  </div>
                  <PadigreeDetails dog_id={padigree?.motherMother_id}/>
                </span>
                <div className="branch">
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-iii">
                          <img
                            src={
                              padigree&&
                                 padigree?.mothersmothersfather_image
                                || placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.mothersmothersfather?padigree?.mothersmothersfather?.slice(0, 13) +
                              "...." : "Unkown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails dog_id={padigree?.mothersmothersfather_id}/>
                    </span>
                  </div>
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-iii">
                          <img
                            src={
                              padigree&&
                                 padigree?.mothersmothersmother_image
                                || placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.mothersmothersmother?padigree?.mothersmothersmother?.slice(0, 13) +
                              "...." : "Unkown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails dog_id={padigree?.mothersmothersmother_id}/>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="col-md-2  rounded justify-content-center align-items-center">
      {/* <img className="rounded mt-2 w-100" src={sideimg} /> */}
    </div>
  </motion.div>
   
  );
};

export default Padigree;
