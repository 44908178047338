import React, { useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { getAllBlogs } from '../api'
import BlogPost from './blog_post'

const Blog = () => {
    const [posts,setPosts]=useState([])
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false }, [Autoplay()])

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getAllBlogs()
            setPosts(res&&res)
        }
        fetchData()
    },[])

    return (
        <div className="row justify-content-center ">
            {
                posts&&posts.length&&posts?.slice(0,3)?.map(({title,link,preview_text})=>(
                    <BlogPost title={title} description={preview_text} link={link}/>
                ))
            }
      </div>
    )
}

export default Blog;

