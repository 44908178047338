import React from 'react'
import BannerImg from '../assets/Images/GC-home.jpg'

const MobileBanner= () => {
    return (
        <div className='mobile-banner-main mt-5 mb-2 justify-content-center align-items-center'>
            <div className='mobile-banner-wrapper'>
            <img  src={BannerImg}/>
            <div className="banner-desc d-flex flex-column justify-content-start align-items-start">
                    <h1 className="text-left">Welcome To <span className='d-block'>The Gundog Club</span></h1>
                    {/* <h4 className="text-left">Graded Training For Gundog Breeds.</h4> */}
                </div>
            </div>
        </div>
    )
}

export default MobileBanner
