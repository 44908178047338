import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllEventsAfter } from "../../api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { motion } from "framer-motion";

const ShowResultsLinsting = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      const res = await getAllEventsAfter();
      setEvents(res && res);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    fetchEvents();
  }, []);
  const pageTransition = {
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: "-100vw",
    },
  };

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransition}
      className="mt-5 container p-2"
    >
      <div className="d-flex justify-content-between flex-wrap align-items-center">
        <h1>GDCP EVENTS' RESULT(S)</h1>
        <Link className="butn shadow-sm rounded" to="/events">
          Back to Events
        </Link>
      </div>
      <div className="row">
        {loading
          ? [0, 1, 2, 3].map((a, ind) => (
              <div className="col-md-3 mb-3 pb-2" key={ind}>
                <Skeleton height={30} className="mb-2" />
                <Skeleton height={15} count={3} width={"60%"} />
                <Skeleton height={25} width={"70%"} />
                <div className="row justify-content-start mt-3">
                  <div className="col-md-12">
                    <Skeleton height={40} className=" btn" />
                  </div>
                </div>
              </div>
            ))
          : events
              ?.reduce((arr, item) => {
                const filtered = arr.filter((i) => i["id"] !== item["id"]);
                return [...filtered, item];
              }, [])
              .map(
                ({
                  title,
                  start_date,
                  venue,
                  club_judge,
                  visit_judge,
                  friendly_URL,
                }) => (
                  <div className="col-md-3">
                    <div className="mt-4">
                      <div
                        className="card bg-light shadow-sm"
                        style={{ width: "18rem" }}
                      >
                        <div className="card-body">
                          <h5 className="fw-bold my-3">{title}</h5>
                          <hr></hr>
                          <span className="d-block my-2">
                            <label className="fw-bold mx-2">Date:</label>
                            {new Date(start_date).toDateString()}
                          </span>
                          <span className="d-block my-2">
                            <label className="fw-bold mx-2">Venue:</label>{" "}
                            {venue}
                          </span>
                          <span className="d-block my-2">
                            <label className="fw-bold mx-2">Judge(s):</label>
                            {club_judge
                              ? club_judge
                              : "" + " " + visit_judge
                              ? visit_judge
                              : "" || ""}
                          </span>
                          <hr></hr>
                          <Link
                            to={friendly_URL || "#"}
                            className="btn mt-2 btn-primary w-100 shadow-sm"
                          >
                            Results
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
      </div>
    </motion.div>
  );
};

export default ShowResultsLinsting;
