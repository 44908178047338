import React, { useState, useEffect } from "react";
import { kennelsearch, getAllkennels } from "../../api";
import placeHolder from "../../assets/Images/image_paceholder.png";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../assets/Images/loader.gif";
import logo from '../../assets/Images/website_logo_large-300x241.png';
import Sidebar from "../../layouts/SideNavigation";
import { Link } from "react-router-dom";

const Kennels = () => {
  const [kennelsData, setkennelsData] = useState([]);
  const [searchLetters, setLetters] = useState([]);
  const [kennels, setkennels] = useState();
  const [loading, setLoading] = useState(false);

  function getUnique(array){
    var uniqueArray = [];
    // Loop through array values
    for(var value of array){
        if(uniqueArray.indexOf(value) === -1){
            uniqueArray.push(value);
        }
    }
    return uniqueArray;
}

  useEffect(async () => {
    const data = await getAllkennels();
    setkennels(data);
    let letter_arr = [];
    if (kennelsData) {
      for (let i of kennelsData) {
        letter_arr.push(i?.kennel_name?.charAt(0));
      }
    }
    setLetters(getUnique(letter_arr));
    console.log("letters", searchLetters);
  }, []);

  useEffect(async () => {
    setkennelsData(kennels);
    let letter_arr = [];
    if (kennelsData) {
      for (let i of kennelsData) {
        letter_arr.push(i?.kennel_name?.charAt(0));
      }
    }
    setLetters(getUnique(letter_arr));
    console.log("letters", searchLetters);
  }, [kennels]);

  const filterByFirstLtr = (letter) => {
    if (letter == "*") {
      setkennelsData(kennels);
    }
    else {
      const data = kennels?.filter((data) => data.kennel_name.charAt(0) == letter);
      console.log(data);
      setkennelsData(data);
    }
  };

  const handleChange = async (e) => {
    setLoading(true);
    let result = await kennelsearch(e);
    if (e === "") {
      setkennelsData(kennels);
      let letter_arr = [];
      if (kennelsData) {
        for (let i of kennelsData) {
          letter_arr.push(i?.kennel_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
      console.log("letters", searchLetters);
    } else {
      setkennelsData(result);
      let letter_arr = [];
      if (kennelsData) {
        for (let i of kennelsData) {
          letter_arr.push(i?.kennel_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
      console.log("letters", searchLetters);
    }
    console.log("search", result);
    setLoading(false);
  };

  return (
    <div className="row mx-0 bg-white rounded p-2 ">
    {/* <div className="col-md-2  rounded">
      <div className="logo">
        <img className="" src={logo} />
      </div>
      <hr />
    </div> */}
    <div className="col-md-12 my-2 px-0 ">
    <div className="container mt-3 w-100 d-flex justify-content-end px-2">
        <input
          type="search"
          className="form-control  search-input w-50  my-3 mx-3"
          PlaceHolder="Search by Kennel Name , User Name, Suffix , Prefix and City"
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>

      <div className="container  w-100 flex-wrap d-flex justify-content-end px-2 ">
        <button
          onClick={() => {
            filterByFirstLtr("*");
          }}
          className="filterBtn my-2 "
        >
          All
        </button>
        {searchLetters &&
          searchLetters.sort().map((item, ind) => (
            <button
              key={ind}
              onClick={() => {
                filterByFirstLtr(item);
              }}
              className="filterBtn my-2 "
              >
              {item}
            </button>
          ))}
            <button
          onClick={() => {
            filterByFirstLtr("asc");
          }}
          className="filterBtn my-2 "
        >
          A - Z
        </button>
            <button
          onClick={() => {
            filterByFirstLtr("dec");
          }}
          className="filterBtn my-2 "
        >
          Z - A
        </button>
      </div>

      <div className="d-flex justify-content-center align-items-center flex-wrap">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "500px" }}
          >
            <img height={60} src={loader} />
          </div>
        ) : kennelsData && kennelsData ? (
          kennelsData?.map(({ kennel_name, image,id}) => (
            <Link to={`/kennels-directory/${id}`} style={{textDecoration:"none"}}>
            <div className="card shadow-sm my-2 mx-2" style={{ width: "15rem" }}>
              <div className="breed-img">
                {image != "" || null ? (
                  <img
                    className="card-img-top"
                    src={
                      "https://kennelclub.pk/storage/app/public/breed_images/" +
                      image
                    }
                    alt="Card image cap"
                  />
                ) : (
                  <img
                    className="card-img-top"
                    src={placeHolder}
                    alt="Card image cap"
                  />
                )}
              </div>
              <div className="card-body ">
                <h5 className="card-title ">{kennel_name}</h5>
                <h5 className="card-title ">{}</h5>
                {/* <a href="#" className="btn-grad">Go somewhere</a> */}
              </div>
            </div>
            </Link>
          ))
        ) : null}
      </div>

    </div>
    <div className="col-md-2  rounded justify-content-center align-items-center">
      {/* <img className="rounded mt-2 w-100" src={sideimg} /> */}
    </div>
  </div>
   
  );
};

export default Kennels;
