import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getAllBreeds } from '../../api';
import { baseURL } from '../../constants';
import placeHolder from '../../assets/Images/placeholder.jpg'
import Loader from '../../components/loader';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {motion} from 'framer-motion';

const Breeds = () => {

const [breeds,setBreeds]=useState([]);
const [loading,setLoading]=useState(false);

useEffect(()=>{

const fetchData=async()=>{
  setLoading(true)
    const res=await getAllBreeds()
    setBreeds(res&&res)
    setTimeout(()=>{setLoading(false)},1000)
}

fetchData()
   
},[])

const imgUrl=baseURL+"/storage/app/public/breed_images/"
      const pageTransition={
        in:{
            opacity:1,
            x:0
        },
        out:{
            opacity:0,
            x:'-100vw'
        }
    }

    return (
        <motion.div 
        initial="out"
        animate="in"
        exit="out"
        variants={pageTransition}
        className="mt-5 container">
      <h1>GDCP Breeds</h1>
        <div className="row ">

            {
                loading?
                (
                  [0,1,2,3,4,5,6,7,8,9,10,11]?.map((a,ind)=>(
                    <div className="col-md-3 my-2" key={ind}>
                      <Skeleton height={250}/>
                      <Skeleton height={18}/>
                      <Skeleton height={18} width={'60%'}/>
                      <div className='row justify-content-between mt-2'>
                        <div className='col-md-4'>
                        <Skeleton height={30}/>
                          </div>
                          <div className='col-md-4'>
                        <Skeleton height={30}/>
                          </div>
                      </div>
                      <hr/>
                    </div>
                    ))
                )
                
                :
                breeds&&breeds?.length?breeds?.map(({breed,image,friendly_url})=>(
                    <div className="col-md-3 my-2">
                    <div className="card shadow rounded" style={{minHeight:'450px'}}>
              {
                image?
                <LazyLoadImage
                alt={breed}
                effect="blur"
                maxHeight={150}
                aiamgelt="Card image cap"
                className="card-img-top p-2" 
                width={'auto'}
                src={imgUrl+image} />:
                <img className="card-img-top" src={placeHolder} 
                alt={breed} aiamgelt="Card image cap" style={{maxHeight:200, width:'auto'}}/>
              }
     


                    <div className="card-body d-flex flex-column justify-content-between align-items-start">
                      <h4 className="card-title">{breed}</h4>
                        <div className="d-flex justify-content-between align-items-center">
                            <Link className="butn   shadow"  to={`/gdcp-database/${friendly_url}`||"#"}>View Dogs</Link>
                            <Link className="butn  shadow" to={friendly_url||"#"}>Read More</Link>
                        </div>
                    </div>
                  </div>
                  </div>
                )):<h1>No Breed found.</h1>
            }
   
     

      </div> 
      </motion.div > 
    )
}

export default Breeds
