import React, { useState, useEffect, useContext } from "react";
import logo from '../../assets/Images/website_logo_large-300x241.png';
import placeHolder from '../../assets/Images/image_paceholder.png';
import { GlobalContext } from "../../context/store";
import { Outlet, useParams,useNavigate } from "react-router";
import { getAllDogsByOwner, getKennelsByOwner, getUserById } from "../../api";
import ProgenyChild from "../../components/progeny_child";
// import Kennels from "../../Kennels";
import Kennel from "../../components/kennel";
import Sidebar_Member from "../../layouts/SideNavigation_Member";

const Profile= () => {
  const {user}=useContext(GlobalContext)
  const [user_data,setUserData]=useState()
  const [kennels,setKennels]=useState([])
  const [dogs,setDogs]=useState([""])
  const {id}=useParams()
  useEffect(async() => {
    if (!id&&user) {
      setUserData(user)
    }
    else if(id){
      const res=await getUserById(id)
        setUserData(res&&res[0])
    }

    const kenl_res=await getKennelsByOwner(id||user?.id)
    setKennels(kenl_res)

    const dogs_res=await getAllDogsByOwner(id||user?.id)
    setDogs(dogs_res)

  }, [user])

  return (
    <div className="row mx-0 mt-5 bg-white rounded p-2 ">
    <div className="col-md-2  mt-5 rounded">
   
    <Sidebar_Member/>
    </div>
    <div className="col-md-10 my-2 px-4 ">
        <Outlet/>
    </div>
  
  </div>
  
  );
};

export default Profile;
