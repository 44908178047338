import React, { useEffect, useState } from 'react'
import { getAboutClub } from '../../../api'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {motion} from 'framer-motion'

const AboutClub = () => {

    const [data,setData]=useState({})
    const [loading,setLoading]=useState(false)
    useEffect(()=>{

            const fetchData=async()=>{
                setLoading(true)
                const res=await getAboutClub()
                setTimeout(()=>{
                    setData(res&&res[0])
                    setLoading(false)
                },2000)
               
            }
            fetchData()

    },[])

    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }
    const pageTransition={
        in:{
            opacity:1,
            x:0
        },
        out:{
            opacity:0,
            x:'-100vw'
        }
    }
    return (
        <motion.div
        variants={pageTransition}
        animate="in"
        initial="out"
        exit="out"
        >
            {
                loading?
            <Skeleton height={40} width={'40%'}/>:
            <h1>About Club</h1>
            }
            {
                loading?
            <Skeleton height={18} count={14} width={'100%'}/>:
            <div className="p-3" dangerouslySetInnerHTML={{__html: convertHTMLEntity(data?.content)}}></div>
            }
           
          
        </motion.div>
    )
}

export default AboutClub
