import Header from '../../layouts/Header';
import Banner from '../../components/banner';
import overlayImg from '../../assets/Images/overlay.jpg'
import Footer from '../../layouts/Footer';
import Blog from '../../components/blog';
import BreedSlider from '../../components/breedsSlider';
import MobileBanner from '../../components/mobile-banner';
import MobileBlog from '../../components/mobile-blog';

function Home() {
  return (
    <div className="App">
      <div className="content_wrapper">
      <Banner/>
      <MobileBanner/>
      <div className="bg-light py-5">
      <h1 className="text-center">Breeds</h1>
      <BreedSlider/>
      </div>
      <div className="events-main">   
      <div className="wg-box-content-overlay"></div>
      <img src={overlayImg}/>   
      <div className="wg-box-content-details wg-box-fadeIn-bottom">
      <h1 className="my-2">News & Events</h1>
      <p className="text-center" style={{maxWidth: '800px', margin: 'auto'}}>
        Stay up to date on our latest articles, press releases,
        and what we are doing to serve and bring change to our community.</p>
      <div className="container mt-4">
      <Blog/>
   
      </div>
      </div>
      </div>
      <div className='container-fluid'>

      <MobileBlog/>
      </div>
      </div>
    </div>
  );
}

export default Home;
