import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getRule } from '../../../../api'
import {motion} from 'framer-motion'

const Rule = () => {
    const {slug}=useParams()
    const[rule,setRule]=useState({})

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getRule(slug)
            setRule(res&&res[0])
        }
        fetchData()
        console.log("data",rule)
    },[slug])

    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }

    const pageTransition={
        in:{
            opacity:1,
            x:0
        },
        out:{
            opacity:0,
            x:'-100vw'
        }
    }

    return (
        <motion.div 
        initial="out"
        animate="in"
        exit="out"
        variants={pageTransition}>
                 <div dangerouslySetInnerHTML={{__html: convertHTMLEntity(rule?.content)}}></div>
      </motion.div>
    )
}

export default Rule
