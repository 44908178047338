import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import {
  getAllBreeds,
  getAllBreedsByGroup,
  getAllClasses,
  getAllGroups,
  getEventByID,
  getEventID,
  getShowResults,
} from "../../api";
import Badge from "../../components/badge";
import { toast } from "react-toastify";

const ShowResults = () => {
  const { slug } = useParams();
  const [eId, setEid] = useState(null);
  const [event, setEvent] = useState(null);
  const [breeds, setBreeds] = useState([]);
  const [groups, setGroups] = useState([]);
  const [classes, setClasses] = useState([]);
  const [breed, setBreed] = useState(0);
  const [group, setGroup] = useState(0);
  const [classname, setClass] = useState(0);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [gander, setGander] = useState("");
  const gradingSortHelper = {
    Excellent: 0,
    "Very Good": 1,
    Good: 2,
    Satisfactory: 3,
    "Not Graded": 4,
    Disqualified: 5,
  };

  const notify = () =>
    toast.info("Please complete the results parameters!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    const fetchData = async () => {
      const res = await getEventID(slug);
      setEid(res && res[0]?.id);
      const res_ = await getEventByID(res && res[0]?.id);
      setEvent(res_ && res_[0]);
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
    const fetchGroups = async () => {
      const res = await getAllGroups();
      setGroups(res && res);
      const res_ = await getAllClasses({ eventType: "'" + event?.type + "'" });
      setClasses(res_ && res_);
      console.log("'" + event?.type + "'", "type");
    };
    fetchGroups();
  }, [event]);

  useEffect(() => {
    const fetchBreeds = async () => {
      // const res = await getAllBreedsByGroup(group);
      const res = await getAllBreeds();
      console.log(res);
      setBreeds(res && res);
    };
    fetchBreeds();
  }, [group]);

  const fetchResults = async (item) => {
    setLoading(true);
    // if (eId && group && breed) {
    if (eId && breed) {
      let body = { showId: eId, classId: item?.id, breedId: breed };
      const res = await getShowResults(body);
      console.log(body);
      res.sort(function (a, b) {
        return (
          gradingSortHelper[a.grading] - gradingSortHelper[b.grading] ||
          a.seat - b.seat
        );
      });
      setResults(res && res);
      setGander("Female");
      setLoading(false);
    } else {
      setLoading(false);
      notify();
    }
  };

  const filterByGander = (g) => {
    if (g == "") {
      return results;
    }
    const d = results.filter((result) => result.sex == g);
    return d;
  };

  return (
    <div className="p-5 mt-5 container">
      <div className="d-flex justify-content-between flex-wrap align-items-center">
        <h2>{event && event?.title}</h2>
        <Link className="butn shadow-sm rounded" to="/events">
          Back to Events
        </Link>
      </div>
      <div className="mt-3 d-flex ">
        <h5 className="mr-4">
          <b>Date:</b> {new Date(event && event?.start_date).toDateString()}
        </h5>
        {event && event?.venue ? (
          <h5 className="mx-4">
            <b>Venue:</b> {event && event?.venue}
          </h5>
        ) : (
          ""
        )}
        {event && event?.judge ? (
          <h5 className="mx-4">
            <b>Judge(s):</b>
            {event && event?.judge}
          </h5>
        ) : (
          ""
        )}
      </div>
      <hr />
      <div className="row">
        {/* <div className="col-md-6">
          <label htmlFor="group">Select Group</label>
          <Select
            className="mt-3"
            options={groups?.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            onChange={(e) => {
              setResults([]);
              setClass({});
              setGroup(e?.value);
            }}
          />
        </div> */}
        <div className="col-md-6">
          <label htmlFor="breed">Select Breed</label>
          <Select
            className="mt-3"
            options={breeds?.map(({ breed, id }) => ({
              label: breed,
              value: id,
            }))}
            onChange={(e) => {
              setResults([]);
              setClass({});
              // Issue 24
              setBreed(e?.value);
            }}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap my-4">
        {classes?.map((item) => (
          <button
            onClick={(e) => {
              setClass(item);
              fetchResults(item);
            }}
            id={classname?.id === item?.id && "active-button"}
            className="btn butn-grey p-2 shadow-sm border mx-2"
          >
            {item?.class}
          </button>
        ))}
      </div>
      {!loading && results.length ? (
        <div className="my-5">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="">{classname?.class}</h4>
            <div className="d-flex justify-content-around w-25 my-4">
              <div class="form-check ">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gander"
                  id="male"
                  value="Male"
                  onClick={(e) => setGander(e.target.value)}
                />
                <label class="form-check-label" for="male">
                  Male
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="gander"
                  id="female"
                  value="Female"
                  defaultChecked
                  onClick={(e) => setGander(e.target.value)}
                />
                <label class="form-check-label" for="female">
                  Female
                </label>
              </div>
            </div>
          </div>
          <table class="table  shadow p-2 rounded">
            <thead class="shadow-sm bg-dark text-light ">
              <tr>
                <th scope="col">Grading</th>
                <th scope="col">Rank</th>
                <th scope="col">Name of Dog</th>
                <th scope="col">Name of Sire</th>
                <th scope="col">Name of Dam</th>
                <th scope="col">Owner</th>
              </tr>
            </thead>
            <tbody>
              {results && results.length ? (
                filterByGander(gander)
                  // ?.sort((a, b) => (a.seat < b.seat ? 1 : -1))
                  ?.map((item) => (
                    <tr className={item?.seat == 1 ? "bg-light  border" : ""}>
                      <th scope="row">{item?.grading || "-"}</th>
                      <td>
                        {item?.seat === 1 ? (
                          <Badge title="Class Winner" />
                        ) : item?.seat === 2 ? (
                          "Second"
                        ) : item?.seat === 3 ? (
                          "Third"
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{item?.dog_name || "_"}</td>
                      <td>{item?.sire_name || "_"}</td>
                      <td>{item?.dam_name || "_"}</td>
                      <td>{item?.owners || "_"}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="6" align="center">
                    <h4 class="my-2">No result(s) found</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default ShowResults;
